import React from "react";
import styles from "./styles.module.scss";

import { ReactComponent as ComingSoon } from "assets/svg/comingSoon.svg";

const Insights = () => {
  return (
    <div className={styles.insight}>
      <div className={styles.insight_empty}>
        <ComingSoon />
      </div>
    </div>
  );
};

export default Insights;
