import React, { useEffect, useState } from "react";
import { Button } from 'elements';
import classes from "./addOption.module.scss";

import { ReactComponent as CloseIcon } from "./close.svg";

import { useDispatch } from "react-redux";

import { useLocation, useParams } from "react-router-dom";
import useWindowSize from "../../../../utils/useWindowSize";
import { AddOptionRows } from "redux/DecisionMatrix/AddOptionRows/action";
import { UpdateOption } from "redux/DecisionMatrix/UpdateOptions/action";

const AddOptionLink = ({
  value,
  setIsAddOptions,
  setIsAddCriteria,
  setIsAddLink,
  option,
  setOption,
  isShortList,
}) => {
  const { decisionId, optionId } = useParams();
  const [valueInput, setValue] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false)

  // const [valueOptionInput, setOptionValue] = useState(0);
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  pathname = pathname.split("/");
  useEffect(() => {
    if (value === "link") {
      setValue(option?.link || "");
    }
  }, [option]);

  const onClose = () => {
    if (value === "option") {
      setIsAddOptions(false);
    }
    if (value === "criteria") {
      setIsAddCriteria(false);
    }
    if (value === "link") {
      setIsAddLink(false);
    }
  };

  const onSave = (event) => {
    if (!error){
      setError(false)
      if (value === "option") {
        // dispatch(assignOption({decision: decisionId, name: valueInput}))
        dispatch(
          AddOptionRows({ num_rows: valueInput, decision_id: decisionId })
        );
        setIsAddOptions(false);
      }

      if (value === "link") {
        dispatch(
          UpdateOption({ id: optionId, body: { link: valueInput } }, isShortList)
        );
        setOption({ ...option, link: valueInput });
        setIsAddLink(false);
      }
    } else {
      setError(true)
      event.preventDefault()
    }

    // if(value === 'criteria'){
    //
    //     dispatch(assignCriteria({decision:decisionId,title:valueInput}))
    //    close()
    //
    // }
  };

  const customValidityText = event => {
    if (pathname.includes("option") && valueInput) {
      event.target.setCustomValidity('Please Enter a Valid URL')
    }
  }

  const resetCustomValidity = event => {
    if (!event.target.value) {
      event.target.setCustomValidity('')
    }
  }
  const onChangeValue = e => {
    if (e.target.value.length == 0) {
      setErrorMessage("This field is required.")
      setValue(e.target.value)
      setError(true)
      
    } else if(e?.target?.value?.length > 255){
      setError(true)
      setErrorMessage("Link cannot be more than 255 characters.")
      setValue(e.target.value)

    } else {
      setError(false)
      setErrorMessage("")
      setValue(e.target.value)
    }
    
  }
  return (
    <div className={classes.bg}>
      <form className={classes.wrapper} onSubmit={onSave}>
        <div className={classes.header}>
          <span>
            {option?.link ? "Edit" : "Add"} {value}
          </span>
          <button className={classes.close} type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={classes.body}>
          <div style={{ color: "black", marginBottom: "20px" }}>
            {value === "option"
              ? " No.of rows"
              : value === "criteria"
                ? ""
                : "Link"}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              value={valueInput}
              onChange={(e) => onChangeValue(e)}
              type={pathname.includes("option") ? "url" : "number"}
              max={5}
              min={1}
              // onInvalid={customValidityText}
              onInput={resetCustomValidity}
              placeholder={
                value === "option"
                  ? "Enter no. of options you want to add"
                  : value === "criteria"
                    ? "Enter criteria"
                    : "Enter URL of option"
              }
            />
            {error ? <span style={{ color: "red", marginLeft: "5px", fontSize: "15px" }}>{errorMessage}</span> : <span style={{ height: "24px" }}></span>}
          </div>
        </div>
        {width > 767 ? (
          <div className={classes.button}>
            <Button>Save</Button>
          </div>
        ) : (
          <div className={classes.buttonMob}>
            <Button>Save</Button>
          </div>
        )}
      </form>
    </div>
  );
};

export { AddOptionLink };
