import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { ReactComponent as CloseIcon } from "./close.svg";

import classes from "./styles.module.scss";
import { AddOptionRows } from "redux/DecisionMatrix/AddOptionRows/action";
import { ReactComponent as ArrowDown } from "../../../../assets/svg/arrowDown.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/svg/ArrowUp.svg"
const AddOptionsModal = ({ onClose }) => {
  const { decisionId } = useParams();
  const $input = useRef();
  const [value, setValue] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    if ($input && $input.current) {
      $input.current.focus();
      $input.current.select();
    }
  }, []);

  const onSubmitHandle = (event) => {
    event.preventDefault();

    dispatch(AddOptionRows({ num_rows: value, decision_id: decisionId }));

    onClose();
  };

  return (
    <div className={classes.bg}>
      <form className={classes.wrapper} onSubmit={onSubmitHandle}>
        <div className={classes.header}>
          <span>Add Multiple Option Rows</span>
          <button className={classes.close} type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={classes.body}>
          <label>Specify the number of options:</label>
          <input
            ref={$input}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type="number"
            max={30}
            min={1}
            required
          />
          <div className={classes.arrow}>
            <ArrowUp
              onClick={() => setValue(parseInt(value) + 1)}
              style={{ width: "14px", height: "14px" }}
            />
            <ArrowDown
              onClick={() => value > 0 && setValue(parseInt(value) - 1)}
            />
          </div>
        </div>
        <div className={classes.footer}>
          <button>Add</button>
        </div>
      </form>
    </div>
  );
};

export { AddOptionsModal };
