import React, { useState } from "react";
import styles from "./styles.module.scss";

// Icons
import { ReactComponent as Search } from "assets/svg/Search.svg";
import { ReactComponent as GoalLogo } from "assets/svg/logoGoal.svg";

import { getPath } from "../AppSwitcher";

const GoalSearch = ({
  allGoalList,
  goalRef,
  onwer,
  setSelectedGoal,
  setGoalName,
  setIsGoalModel,
  isGoalSelect,
  setIsGoalSelect,
  selectedGoal,
  isHeader = false,
  isProjectDetail = false,
}) => {
  const { gpsURL } = getPath();

  const [searchGoal, setSearchGoal] = useState("");

  const filterGoalList = allGoalList?.filter((goal) =>
    goal?.goal_name.toLowerCase().includes(searchGoal.toLowerCase())
  );

  return (
    <>
      {onwer && allGoalList?.length > 0 ? (
        <div
          className={styles.goal_select_lists}
          ref={goalRef}
          style={{
            visibility: isGoalSelect ? "visible" : "hidden",
            opacity: isGoalSelect ? 1 : 0,
            top: isHeader ? "70px" : "45px",
            left: isHeader || isProjectDetail ? "0px" : "10px",
            width: isHeader ? "97%" : "100%",
          }}
        >
          <div className={styles.goal_search}>
            <input
              placeholder="Search goal"
              value={searchGoal}
              onChange={(e) => setSearchGoal(e.target.value)}
            />
            <Search />
          </div>

          {filterGoalList?.length > 0 ? (
            filterGoalList?.map((goal, index) => {
              const color = goal?.color;

              return (
                <div
                  className={styles.goal_select_list}
                  key={index}
                  style={{
                    borderLeftColor: color || "#A3A3A3",
                    backgroundColor:
                      selectedGoal?.id === goal?.id ? "#f8f8f8" : "#fff",
                  }}
                  onClick={() => {
                    setSelectedGoal && setSelectedGoal(goal);
                    setGoalName && setGoalName(goal?.goal_name);
                    setIsGoalModel && setIsGoalModel(true);
                    setIsGoalSelect && setIsGoalSelect(false);
                  }}
                >
                  <h3>{goal?.goal_name || "No name"}</h3>
                </div>
              );
            })
          ) : (
            <div className={styles.noGoal}>
              <h3>No goal found</h3>
            </div>
          )}

          <div className={styles.createGaol}>
            <button
              onClick={() => {
                window.open(`https://${gpsURL}`, "_blank");
              }}
            >
              Create Goal
            </button>
          </div>
        </div>
      ) : (
        onwer && (
          <div
            ref={goalRef}
            className={styles.goal_select}
            style={{
              visibility: isGoalSelect ? "visible" : "hidden",
              opacity: isGoalSelect ? 1 : 0,
              top: isHeader ? "70px" : "45px",
              left: isHeader ? "0px" : "10px",
              width: isHeader ? "97%" : "100%",
              height: isHeader ? "250px" : "200px",
            }}
          >
            <GoalLogo />

            <h3>You don’t have any goal created yet.</h3>

            <button
              onClick={() => {
                window.open(`https://${gpsURL}`, "_blank");
              }}
            >
              Go to Goal Manager
            </button>

            <span onClick={() => setIsGoalSelect(false)}>Not Now</span>
          </div>
        )
      )}
    </>
  );
};

export default GoalSearch;
