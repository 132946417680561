import Cookies from "js-cookie";
import moment from "moment";

// import familyIcon from '../assets/images/areas/FamilyIcon.svg';
// import healthIcon from '../assets/images/areas/HealthIcon.svg';
// import partnerIcon from '../assets/images/areas/PartnerIcon.svg';
// import growthIcon from '../assets/images/areas/GrowthIcon.svg';
// import careerIcon from '../assets/images/areas/CareerIcon.svg';
// import contributionIcon from '../assets/images/areas/ContributionIcon.svg';
// import financeIcon from '../assets/images/areas/FinanceIcon.svg';
// import socialIcon from '../assets/images/areas/SocialIcon.svg';
// import leisureIcon from '../assets/images/areas/LeisureIcon.svg';
// import environmentIcon from '../assets/images/areas/EnvironmentIcon.svg';
import { abbreviateNumber } from "js-abbreviation-number";
import { durationList } from "./constants";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "redux/store";
import { checkAuth } from "modules/actions/AuthActions";
import { ALLOCATOR_TOOL } from "./routes";

import { ReactComponent as CompleteIcon } from "../scenes/PlanDetails/ActionItems/Category/complete.svg";
import { ReactComponent as OnHoldIcon } from "../scenes/PlanDetails/ActionItems/Category/onHold.svg";
import { ReactComponent as TodoIcon } from "../scenes/PlanDetails/ActionItems/Category/todo.svg";
import { ReactComponent as InProgessIcon } from "../scenes/PlanDetails/ActionItems/Category/inProgress.svg";

/* export const isLoggedIn = () => {
  const token = localStorage.getItem('token')
  return token
} */

// export const getAreaIcon = (code) => {
//   switch (code) {
//     case 'famil':
//       return familyIcon;
//     case 'healt':
//       return healthIcon;
//     case 'partn':
//       return partnerIcon;
//     case 'growt':
//       return growthIcon;
//     case 'caree':
//       return careerIcon;
//     case 'contr':
//       return contributionIcon;
//     case 'finan':
//       return financeIcon;
//     case 'socia':
//       return socialIcon;
//     case 'leisu':
//       return leisureIcon;
//     case 'envir':
//     default:
//       return environmentIcon;
//   }
// };

export const getAreaColor = (code) => {
  switch (code) {
    case "famil":
      return "#10A3B7";
    case "healt":
      return "#2E99E7";
    case "partn":
      return "#20A58D";
    case "growt":
      return "#9D9655";
    case "caree":
      return "#BA876A";
    case "contr":
      return "#24A431";
    case "finan":
      return "#6B7DDC";
    case "socia":
      return "#9E64CC";
    case "leisu":
      return "#CC6181";
    case "envir":
    default:
      return "#DD6868";
  }
};

export const getAreaBackgroundColor = (code) => {
  switch (code) {
    case "famil":
      return "#E7F6F8";
    case "healt":
      return "#EAF5FD";
    case "partn":
      return "#E9F6F4";
    case "growt":
      return "#F5F5EE";
    case "caree":
      return "#F8F3F0";
    case "contr":
      return "#E9F6EA";
    case "finan":
      return "#F0F2FB";
    case "socia":
      return "#F5F0FA";
    case "leisu":
      return "#FAEFF2";
    case "envir":
    default:
      return "#FCF0F0";
  }
};

// NOTE: returns start and end dates in ISO format
export const getStartEndDates = (startDate, endDate) => {
  let start = new Date(startDate).setHours("00");
  start = new Date(start).setMinutes("01");
  start = new Date(start).toISOString();

  let end = new Date(endDate).setHours("23");
  end = new Date(end).setMinutes("59");
  end = new Date(end).toISOString();

  return { start, end };
};

export const getStartEndDatesV2 = (startDate, endDate) => {
  return {
    start: moment(startDate).format("YYYY-MM-DD"),
    end: moment(endDate).format("YYYY-MM-DD"),
  };
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const truncateEmail = (email) => {
  if (email && email.length > 30) {
    return `${email.substring(0, 28)}...`;
  }
  return email;
};

export const getDateOfSelectedWeek = (selectedWeek, day) => {
  const selected = selectedWeek.map((d) => moment(d).format("YYYY-MM-DD"));
  switch (day) {
    case "mon":
      return selected[0];
    case "tue":
      return selected[1];
    case "wed":
      return selected[2];
    case "thu":
      return selected[3];
    case "fri":
      return selected[4];
    case "sat":
      return selected[5];
    case "sun":
      return selected[6];
    default:
      break;
  }
};

export const getAreaSubtitle = (categories) => {
  return categories.map(({ category }) => category.name).join(", ");
};

export const convertMinToHr = (value) => {
  const duration = value.duration;
  const calculatedDuration =
    duration > 60 ? Math.round(duration / 60) + "H" : duration + "M";
  return calculatedDuration;
};

export const getWeekDays = (weekStart) => {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, "days").toDate());
  }
  return days;
};

export const getWeekRange = (date) => {
  let mDate = moment(date);

  if (mDate.isSame(moment(date).startOf("week"), "day")) {
    mDate.subtract(1, "day");
  }

  const weekDay = mDate.toDate();

  return {
    from: moment(weekDay).startOf("week").add(1, "days").toDate(),
    to: moment(weekDay).endOf("week").add(1, "days").toDate(),
  };
};

export const getFirstAvailableWeight = (existingWeights = []) => {
  const weights = Array.from({ length: 10 }, (_, i) => i + 1).sort(
    (a, b) => b - a
  );
  return weights.find((w) => {
    if (!existingWeights.includes(w)) return w;
  });
};

export const sortHabits = (habits) => {
  const allWeights = Array.from({ length: 10 }, (_, i) => i + 1).sort(
    (a, b) => b - a
  );
  const sortedHabits = [];
  allWeights.forEach((weight) => {
    const habitWithPositiveWeight = habits.find(
      (habit) => habit.weight === weight
    );
    if (habitWithPositiveWeight) {
      sortedHabits.push(habitWithPositiveWeight);
    }
    const habitWithNegativeWeight = habits.find(
      (habit) => habit.weight === -weight
    );
    if (habitWithNegativeWeight) {
      sortedHabits.push(habitWithNegativeWeight);
    }
  });
  return sortedHabits;
};

export const calculateNetReturn = (months = [], obj = {}) => {
  if (!months.length) return 0;
  const previousMonthIncome = obj[`${months[0]}_points`] || 0;
  const currentMonthIncome = obj[`${months[1]}_points`] || 0;
  return abbreviateNumber(
    Math.round(currentMonthIncome - previousMonthIncome),
    1
  );
};

export const calculateNetReturnPercentage = (
  balanceOne,
  balanceTwo,
  isBadHabit = false
) => {
  const profit = Math.round(balanceTwo - balanceOne);
  if (balanceOne === 0) {
    return "-";
  }
  const value = Math.round((+profit / balanceOne) * 100);
  return isBadHabit ? `-${Math.abs(value)}%` : `${value}%`;
};

export const getNetReturnColorCode = (balanceOne, balanceTwo) => {
  if (balanceOne === 0 && balanceTwo === 0) return 0;

  if (balanceOne === 0) return "gray";
  if (balanceTwo > balanceOne) {
    return "green";
  } else if (balanceTwo < balanceOne) {
    return "red";
  } else {
    return "gray";
  }
};

export const formatNumber = (number, decimals, dec_point, thousands_sep) => {
  // *     example 1: number_format(1234.56);
  // *     returns 1: '1,235'
  // *     example 2: number_format(1234.56, 2, ',', ' ');
  // *     returns 2: '1 234,56'
  // *     example 3: number_format(1234.5678, 2, '.', '');
  // *     returns 3: '1234.57'
  // *     example 4: number_format(67, 2, ',', '.');
  // *     returns 4: '67,00'
  // *     example 5: number_format(1000);
  // *     returns 5: '1,000'
  // *     example 6: number_format(67.311, 2);
  // *     returns 6: '67.31'
  // *     example 7: number_format(1000.55, 1);
  // *     returns 7: '1,000.6'
  // *     example 8: number_format(67000, 5, ',', '.');
  // *     returns 8: '67.000,00000'
  // *     example 9: number_format(0.9, 0);
  // *     returns 9: '1'
  // *    example 10: number_format('1.20', 2);
  // *    returns 10: '1.20'
  // *    example 11: number_format('1.20', 4);
  // *    returns 11: '1.2000'
  // *    example 12: number_format('1.2000', 3);
  // *    returns 12: '1.200'

  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    toFixedFix = function (n, prec) {
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      var k = Math.pow(10, prec);
      return Math.round(n * k) / k;
    },
    s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split(".");
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};

/* export const calculateNetReturnPercentage = (
  months = [],
  obj = {},
  isBadHabit = false,
) => {
  if (!months.length) return 0;
  const previousMonthIncome = obj[`${months[0]}_points`] || 0;
  const currentMonthIncome = obj[`${months[1]}_points`] || 0;
  const profit = Math.round(currentMonthIncome - previousMonthIncome);
  if (previousMonthIncome === 0) {
    return '-';
  }
  const value = Math.round((+profit / previousMonthIncome) * 100);
  return isBadHabit ? `-${Math.abs(value)}%` : `${value}%`;
}; */

/* export const getNetReturnColorCode = (months = [], obj = {}, isBadHabit = false) => {
  if (!months.length) return 0;
  const previousMonthIncome = obj[`${months[0]}_points`];
  const currentMonthIncome = obj[`${months[1]}_points`];
  if (previousMonthIncome === 0) return 'gray';
  if (currentMonthIncome > previousMonthIncome) {
    return 'green';
  } else if (currentMonthIncome < previousMonthIncome) {
    return 'red';
  } else {
    return 'gray';
  }
}; */

export const getTotalHours = (duration) => {
  const [days, time] = duration?.split(" ");
  const [hours, minutes] = time?.split(":") || duration?.split(":");
  let totalhours = "";
  if (days && time) {
    totalhours = `${Number(hours) + Number(days) * 24}`;
  } else {
    totalhours = hours;
  }
  const minutesInHours = Number(minutes) / 60;
  return Number(totalhours) + Number(minutesInHours);
};

export const getTime = (str) => {
  const [days, time] = str?.split(" ");
  const [hours, minutes] = time?.split(":") || str?.split(":");
  let totalhours = "";
  if (days && time) {
    totalhours = `${Number(hours) + Number(days) * 24}`;
  } else {
    totalhours = hours;
  }
  return totalhours + " hrs " + minutes + " min";
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
export const move = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const getTimeText = (str) => {
  const res = durationList.filter((e) => e.value === str);
  return res[0].key;
};

export const objectToParams = (obj) => {
  let str = "";
  for (const key in obj) {
    if (str !== "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }
  return `?${str}`;
};

export const onlySpaces = (str) => {
  return /^\s*$/.test(str);
};

export const weightDistributionV4 = (criterias = [], sum = 100) => {
  const array = [];
  const criteriasCopy = [...criterias];

  let totalSum = 0;

  for (let i = 0; i < criteriasCopy.length - 1; i++) {
    const num = Math.floor(
      ((sum - totalSum) * 2) / (criteriasCopy.length - i + 1)
    );
    totalSum += num;
    array.push(num);
  }

  const lastNum = sum - totalSum;

  if (lastNum >= 1) {
    array.push(lastNum);
  } else if (array.length > 0 && array[array.length - 1] < sum) {
    array[array.length - 1] += lastNum;
  }

  for (let i = 0; i < array.length; i++) {
    array[i] = Math.max(1, Math.min(sum, array[i]));
  }

  for (let i = 0; i < criteriasCopy.length; i++) {
    criteriasCopy[i].weight = array[i];
  }

  return criteriasCopy;
};

export const useProject = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [defaultApp, setDefaultApp] = useState(null);

  const redirect = searchParams.get("redirect");

  const currentProject = useMemo(() => {
    if (
      pathname.includes("project-planner") ||
      pathname.includes("shared-project")
    )
      return "project-planner";
    else if (
      pathname.includes("decision-manager") ||
      pathname.includes("shared-decision")
    )
      return "decision-manager";
    else if (
      pathname.includes(ALLOCATOR_TOOL) ||
      pathname.includes("shared-decision")
    )
      return ALLOCATOR_TOOL;
    else if (pathname.includes("mission-statement")) return "mission-statement";
    return null;
  }, [pathname]);

  useEffect(() => {
    const savedApp = Cookies.get("lmos-default-app");
    setDefaultApp(savedApp || "dm");
  }, []);

  useEffect(() => {
    if (
      (redirect && redirect === "dm") ||
      redirect === "pp" ||
      redirect === "at"
    ) {
      setDefaultApp(redirect);
      Cookies.set("lmos-default-app", redirect, 14);
    }
  }, [redirect]);

  useEffect(() => {
    if (currentProject && currentProject === "project-planner") {
      setDefaultApp("pp");
      Cookies.set("lmos-default-app", "pp", 14);
    }
    if (currentProject && currentProject === "decision-manager") {
      setDefaultApp("dm");
      Cookies.set("lmos-default-app", "dm", 14);
    }
    if (currentProject && currentProject === ALLOCATOR_TOOL) {
      setDefaultApp("at");
      Cookies.set("lmos-default-app", "at", 14);
    }
  }, [currentProject]);

  return { project: currentProject, defaultProject: defaultApp };
};

export function getAppBaseURL() {
  try {
    const parsedURL = new URL(window.location.href);
    return parsedURL.hostname;
  } catch (e) {
    console.error("Invalid URL:", url);
    return null;
  }
}

export function getToken() {
  const state = store.getState();
  const token = state.auth.token.data ? state.auth.token.data : null;

  return token;
}

export function useToken() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!token.success && !token.data) {
      dispatch(checkAuth());
    }
  }, []);

  return {
    token: token.data ? token.data : null,
    success: token.success,
    loading: token.loading,
  };
}

export function useAllocatorAccess() {
  const { app_access } = useSelector((state) => state.auth.user.data);

  if (app_access) {
    if (
      app_access?.allocator_tool &&
      app_access?.allocator_tool?.length > 0 &&
      app_access?.allocator_tool.includes("write")
    ) {
      return true;
    }
  }

  return false;
}

export function useUser() {
  const { user } = useSelector((state) => state.auth);
  return user.data;
}

export const CriteriaColors = [
  "#FF5E5E",
  "#B664F7",
  "#6BD4D1",
  "#F7D85C",
  "#F27BA5",
  "#7B91AD",
  "#7C9B8E",
  "#C25656",
  "#A6CE39",
  "#264078",
  "#BAA287",
  "#8A79A9",
  "#D97D42",
  "#A6753A",
  "#3F94E4",
  "#347D7A",
];

export function StatusBadge(status) {

  return status === 1 ? (
    <>
      <TodoIcon /> Todo
    </>
  ) : status === 2 ? (
    <>
      <InProgessIcon /> In Progress
    </>
  ) : status === 3 ? (
    <>
      <OnHoldIcon /> On Hold
    </>
  ) : status === 4 ? (
    <>
      <CompleteIcon /> Completed
    </>
  ) : (
    <>
      <InProgessIcon /> Canceled
    </>
  );
}

export function get_url_title(url) {
  const parts = url.split("/resources/");

  if (parts.length > 1) {
    const fileNameWithExtension = parts[1].split(/[#?]/)[0];
    const fileName = fileNameWithExtension.split(".").slice(0, -1).join(".");
    return fileName;
  }

  return null;
}
