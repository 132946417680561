import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Spin } from "antd";
import debounce from "lodash.debounce";
// import { EnhanceAI } from 'enhanceai';

import classes from "./createOptionCriteria.module.scss";

import { CompareProCons } from "scenes/DecisionMatrix";
import { UpdateOptionCriteria } from "redux/DecisionMatrix/UpdateOptionCriteria/action";
import { BulkUpdateOptionCriteria } from "redux/DecisionMatrix/BulkUpdateOptionCriteria/action"
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as RightIcon } from "./right.svg";
import { ReactComponent as LeftIcon } from "./left.svg";

import { CompareAIAssistantModal, SelectScore } from "../../components";
import { FactsModal } from "../FactsModal/FactsModal";
import { SetGenerateFactsNull } from "redux/DecisionMatrix/GenerateFacts/action";
import { useLocation, useParams } from "react-router-dom";
import { UpdateCriteriaRows } from "../../../../redux/DecisionMatrix/UpdateCriteriaRows/action";

const Notes = ({
  value,
  disabled,
  onChange,
  onClick,
  setInputValue,
  inputValue,
  setIsChange,
}) => {
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={classes.notes_container}>
      <div className="d-flex justify-content-between">
        <label>Facts</label>
        {!disabled && (
          <div className={classes.generateWithAI} onClick={onClick}>
            Generate with AI
          </div>
        )}
      </div>

      <form className={classes.body}>
        <textarea
          value={inputValue || ""}
          placeholder="Enter some facts about this option..."
          onChange={(e) => {
            setIsChange(false);
            setInputValue(e?.target?.value)}
          }
          readOnly={disabled}
          onBlur={() => onChange(inputValue)}
        />
      </form>
    </div>
  );
};

const ScoreWrapper = ({
  criteria,
  optionCriteria,
  disabled,
  setIsUpdateMe,
  isUpdateMe,
  setIsTrue,
  isTrue,
  isShortList,
  activeCriterias,
  setScorePayload,
  setIsChange,
  setRubricsPayload,
  filterScore,
}) => {
  const dispatch = useDispatch();

  const { success: userDecisions, loading } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  const { decisionId, optionId } = useParams();
  const [rubric, setRubric] = useState(null);
  const [scoreCriteria, setScoreCriteria] = useState(false);


  const handleRubricSave = (rubrics, criteria) => {
    setRubric(rubrics)
    setScoreCriteria(criteria)
    setRubricsPayload(
      {
        update: userDecisions?.criteria
        ?.filter((crit) => crit?.id === criteria)
        ?.map(({ rubric, ...rest }) => {
          return { ...rest, rubric: rubrics };
        }),
        decision: decisionId,
      }
    )
    // dispatch(
    //   UpdateCriteriaRows({
    //     update: userDecisions?.criteria
    //       ?.filter((crit) => crit?.id === criteria)
    //       ?.map(({ rubric, ...rest }) => {
    //         return { ...rest, rubric: rubrics };
    //       }),
    //     decision: decisionId,
    //   })
    // );
  };

  return (
    <div className={classes.score_container}>
      <p>Score</p>
      <SelectScore
        value={optionCriteria?.score}
        optionCriteria={optionCriteria || {}}
        curentIndex={false}
        secondary
        isShortList={isShortList}
        rubric={rubric? rubric : criteria?.rubric}
        isReverse={true}
        disabled={disabled}
        setIsUpdateMe={setIsUpdateMe}
        isUpdateMe={isUpdateMe}
        setIsTrue={setIsTrue}
        isTrue={isTrue}
        activeCriterias={activeCriterias}
        onRubricSave={handleRubricSave}
        setScorePayload={setScorePayload}
        setIsChange={setIsChange}
        filterScore={filterScore}
      />
    </div>
  );
};

const CreateOptionCriteria = ({
  criteriaForChanging,
  setCriteriaForChanging,
  isShortList,
  setExpandedFacts,
  CriteriaPayload,
  setCriteriaPayload,
  scoreData,
  setScoreData,
  factsPayload,
  setFactsPayload
}) => {
  const { decisionId, optionId } = useParams();
  const [isFactModalOpen, setIsFactModalOpen] = useState(false);
  const [facts, setFacts] = useState("");
  // const [selectCriteria, setSelectCriteria] = useState("");
  const [isEmptyFact, setIsEmptyFact] = useState();
  const [inputValue, setInputValue] = useState("");
  const [isUpdateMe, setIsUpdateMe] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [isChange, setIsChange] = useState(true);
  const [isLoading, setIsLoading] = useState(false)

  const { success: userDecisions } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  const [activeCriteria, setActiveCriteria] = useState("Certification");
  const [selectedCriterias, setSelectedCriterias] = useState("");
  const [activeCriterias, setActiveCriterias] = useState([]);

  // const [AIselectedCriteria, setAISelectedCriteria] = useState("");
  const [aiAssistant, setAiAssistant] = useState(false);
  const [activeOptionCriteria, setActiveOptionCriteria] = useState("");

  const [selectedOptionId, setSelectedOptionId] = useState(
    criteriaForChanging?.optionId
  );

  const [drawerClosed, setDrawerClosed] = useState(false);
  const dispatch = useDispatch();
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const secure_token = query.get("secure_token");
  const selectedOption = useMemo(() => {
    const updatedOption = { ...userDecisions };
    return updatedOption.options?.find((item) => item.id === selectedOptionId);
  }, [selectedOptionId, isUpdateMe, userDecisions, criteriaForChanging]);

  const selectedCriteria = useMemo(() => {
    return userDecisions.criteria?.find(
      (item) => criteriaForChanging?.criteriaId === item?.id
    );
  }, [criteriaForChanging?.criteriaId]);

  const [consPros, setConsPros] = useState({})
  const [scorePaylaod, setScorePayload] = useState({})
  const [rubricPayload, setRubricsPayload] = useState()
  const [consProsContext, setConsProsContext] = useState({})

  useEffect(() => {
    setInputValue("");
  }, [isEmptyFact]);

  useEffect(() => {
    if (userDecisions?.options && criteriaForChanging?.optionId) {
      setActiveOptionCriteria(
        userDecisions?.options
          ?.find((item) => item?.id == criteriaForChanging?.optionId)
          ?.option_criteria?.find(
            (item) => item?.criteria === activeCriteria?.id
          )
      );
    }
  }, [activeCriteria, userDecisions, selectedCriterias]);

  const filterData = CriteriaPayload?.filter(
    (item) => item?.optionId === criteriaForChanging?.optionId && item?.criteriaId === criteriaForChanging?.criteriaId
  );
  const filterScore = scoreData?.filter(
    (item) => item?.optionId === criteriaForChanging?.optionId && item?.criteriaId === criteriaForChanging?.criteriaId
  );

  const filterFacts = factsPayload?.filter(
    (item) => item?.optionId === criteriaForChanging?.optionId && item?.criteriaId === criteriaForChanging?.criteriaId
  );

  // Function to add or update the object in the array
function addOrUpdateCriteriaPayload(array, newObject) {
  newObject.optionId = criteriaForChanging?.optionId;
  newObject.criteriaId = criteriaForChanging?.criteriaId;

  const index = array.findIndex(
    (item) => item.optionId === newObject.optionId && item.criteriaId === newObject.criteriaId
  );

  if (index !== -1) {
    // If found, update the existing object
    array[index] = { ...array[index], ...newObject };
  } else {
    // If not found, push the new object
    array.push(newObject);
  }
}


  const selectedOptionCriteria = useMemo(() => {
    const updatedOption = { ...selectedOption };
    return updatedOption?.option_criteria?.find(
      (item) =>
        item.criteria === criteriaForChanging?.criteriaId &&
        item.option === selectedOptionId
    );
  }, [selectedOptionId, isUpdateMe, selectedOption, criteriaForChanging]);


  useEffect(() => {
    setFacts(selectedOptionCriteria?.facts);
  }, [selectedOptionCriteria]);

  useEffect(()=>{
    if (!selectedOptionCriteria){
      setIsLoading(true)
    } else {
      setIsLoading(false);
    }
  }, [selectedOptionCriteria])

  useEffect(() => {
    const found = userDecisions?.criteria?.find(
      (e) => e?.id == criteriaForChanging?.criteriaId
    );
    setActiveCriteria({ id: found?.id, title: found?.title });
    setSelectedCriterias({ id: found?.id, title: found?.title });
  }, [userDecisions]);

  const { loading: getDecisionDetailsLoading } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );

  useEffect(()=>{
    if(filterFacts[0]?.facts){
      setInputValue(filterFacts[0]?.facts)
    
    }
  },[filterFacts[0]])

  useEffect(()=>{
    if (filterScore[0]?.data == "N/A"){
      setScorePayload({id:filterScore[0]?.id, is_na:true})
    }
    else {
      setScorePayload({id:filterScore[0]?.id, score:filterScore[0]?.data})

    }

  },[filterScore[0]?.data])
  

  // useEffect(() => {
  //   setIsChange(false)
  // },[inputValue])
  const onSave = (event) => {
    // Save Rubrics 
    if (rubricPayload && !isLoading){
      dispatch(
      UpdateCriteriaRows(rubricPayload)
    );
    }
    // Update score data array while value are upate but not clicking on arrow buttons
    if(Object.keys(scorePaylaod).length !=0  && !isLoading){
      addOrUpdateCriteriaPayload(
        scoreData,
        {
        data:scorePaylaod?.is_na ? "N/A" : scorePaylaod?.score,
        id:scorePaylaod?.id
      })
    }
    // Update all score in score data array
    scoreData?.map((score)=>{
      if(score?.id){
        let payload = {score:score?.data}
        if(score?.data === "N/A"){
          payload = {is_na : true}
        }
        dispatch(
          UpdateOptionCriteria(
            payload,
            score?.id,
            false,
            false
          )
        )
      }
    })

    // Update facts paylaod while facts input have value but user not click on any arrow buttton.
    addOrUpdateCriteriaPayload(
      factsPayload,
      {
      facts:inputValue,
      selectedOptionCriteria: selectedOptionCriteria?.id
    })

    // Update Facts
    factsPayload?.map((item)=>{
      if(item){
        dispatch(UpdateOptionCriteria({'facts': item?.facts }, item?.selectedOptionCriteria, false, false));
      }
    })
    dispatch(SetGenerateFactsNull(null))

    // Save Pros and Cons
    //Add pros and cons while user not click on any arrow buton.
    addOrUpdateCriteriaPayload(
      CriteriaPayload,
      {
      consPros: consPros,
    })
    // Save Pros and Cons
    CriteriaPayload?.map((items)=>{
      if (items?.consPros) {
        const pros = items?.consPros?.pros?.filter((item) => item?.isChange === true).map((item) => {
          const obj = {
            context: item?.context,
            priority: item?.priority,
            option_criteria: item?.option_criteria
          }
          if (item?.id) {
            obj.id = item?.id
          }
          return obj
        })
  
        const cons = items?.consPros?.cons?.filter((item) => item?.isChange === true).map((item) => {
          const obj = {
            context: item?.context,
            priority: item?.priority,
            mitigation: item?.mitigation,
            option_criteria: item?.option_criteria
  
          }
          if (item?.id) {
            obj.id = item?.id
          }
          return obj
        })
        const paylaod = {
          pros: pros,
          cons: cons,
          deleteProsIds: items?.consPros?.deleteProsIds,
          deleteConsIds: items?.consPros?.deleteConsIds
        }
        if (pros?.length !=0 || cons?.length !=0 || paylaod?.deleteConsIds?.length !=0 || paylaod?.deleteConsIds?.length !=0 && !isLoading) {
          dispatch(BulkUpdateOptionCriteria(paylaod))
        }
  
      }

    })
    if(event){
      event.preventDefault();
    }
    setCriteriaForChanging({ showPopup: false });
    setExpandedFacts({});
    setCriteriaPayload([])
    setScoreData([])
    setFactsPayload([])
  };

  const onFactsChangeHandle = (facts) => {
    setInputValue(facts)
    // updateFacts({ facts }, selectedOptionCriteria?.id);
  };

  const updateFacts = useCallback(
    debounce((body, id) => {
      if(id){
        dispatch(UpdateOptionCriteria(body, id, false, false));
      }
      
    }, 500),
    []
  );

  const isDisabledForward = () => {
    let index = isShortList
      ? criteriaForChanging?.options
        .filter((option) => option.shortlist === true)
        ?.findIndex((option) => {
          return option.id === selectedOption?.id;
        })
      : criteriaForChanging?.options?.findIndex((option) => {
        return option.id === selectedOption?.id;
      });
    // let index = criteriaForChanging?.options.findIndex((option) => {
    //   return option.id === selectedOption?.id;
    // });

    if (
      index + 1 ===
      (isShortList
        ? criteriaForChanging?.options?.filter(
          (option) => option.shortlist === true
        )?.length
        : criteriaForChanging.options?.length)
    )
      return true;
    return false;
  };

  const isDisabledBack = () => {
    let index = isShortList
      ? criteriaForChanging?.options
        .filter((option) => option.shortlist === true)
        ?.findIndex((option) => {
          return option.id === selectedOption?.id;
        })
      : criteriaForChanging?.options.findIndex((option) => {
        return option.id === selectedOption?.id;
      });

    if (index === 0) return true;
  };

  const handleForward = () => {
    let index = isShortList
      ? criteriaForChanging?.options
        .filter((option) => option.shortlist === true)
        ?.findIndex((option) => {
          return option.id === selectedOption?.id;
        })
      : criteriaForChanging?.options.findIndex((option) => {
        return option.id === selectedOption?.id;
      });
    setIsEmptyFact(index);
    setSelectedOptionId(
      isShortList
        ? criteriaForChanging?.options.filter(
          (option) => option.shortlist === true
        )[index + 1]?.id
        : criteriaForChanging?.options[index + 1]?.id
    );
    setCriteriaForChanging({
      options: isShortList
        ? criteriaForChanging?.options.filter(
          (option) => option.shortlist === true
        )
        : criteriaForChanging?.options,
      showPopup: true,
      optionId: isShortList
        ? criteriaForChanging?.options.filter(
          (option) => option.shortlist === true
        )[index + 1]?.id
        : criteriaForChanging?.options[index + 1]?.id,
      optionName: criteriaForChanging?.optionName,
      decisionId: criteriaForChanging?.decisionId,
      criteriaId: criteriaForChanging?.criteriaId,
      optionCriteria: criteriaForChanging?.optionCriteria,
      criteriaName: criteriaForChanging?.criteriaName,
      rubric: criteriaForChanging?.rubric,
    });
    addOrUpdateCriteriaPayload(
      CriteriaPayload,
      {
      consPros: consPros,
    })

    if (scorePaylaod?.score || scorePaylaod?.is_na){
      addOrUpdateCriteriaPayload(
        scoreData,
        {
        data:scorePaylaod?.is_na ? "N/A" : scorePaylaod?.score,
        id:scorePaylaod?.id
      })
    }
    addOrUpdateCriteriaPayload(
      factsPayload,
      {
      facts:inputValue,
      selectedOptionCriteria: selectedOptionCriteria?.id
    })
    setScorePayload({})
  };
  const handleBack = () => {
    let index = isShortList
      ? criteriaForChanging?.options
        ?.filter((option) => option.shortlist === true)
        ?.findIndex((option) => {
          return option.id === selectedOption?.id;
        })
      : criteriaForChanging?.options.findIndex((option) => {
        return option.id === selectedOption?.id;
      });
    setIsEmptyFact(index);
    setSelectedOptionId(
      isShortList
        ? criteriaForChanging?.options?.filter(
          (option) => option.shortlist === true
        )[index - 1]?.id
        : criteriaForChanging?.options[index - 1]?.id
    );
    setCriteriaForChanging({
      options: isShortList
        ? criteriaForChanging?.options?.filter(
          (option) => option.shortlist === true
        )
        : criteriaForChanging?.options,
      showPopup: true,
      optionId: isShortList
        ? criteriaForChanging?.options?.filter(
          (option) => option.shortlist === true
        )[index - 1]?.id
        : criteriaForChanging?.options[index - 1]?.id,
      optionName: criteriaForChanging?.optionName,
      decisionId: criteriaForChanging?.decisionId,
      criteriaId: criteriaForChanging?.criteriaId,
      optionCriteria: criteriaForChanging?.optionCriteria,
      criteriaName: criteriaForChanging?.criteriaName,
      rubric: criteriaForChanging?.rubric,
    });
    addOrUpdateCriteriaPayload(
      CriteriaPayload,
      {
      consPros: consPros,
      facts: inputValue
    })
    if (scorePaylaod?.score || scorePaylaod?.is_na){
      addOrUpdateCriteriaPayload(
        scoreData,
        {
        data:scorePaylaod?.is_na ? "N/A" : scorePaylaod?.score,
        id:scorePaylaod?.id
      })
    }
    if (inputValue){
      addOrUpdateCriteriaPayload(
        factsPayload,
        {
        facts:inputValue,
        selectedOptionCriteria: selectedOptionCriteria?.id
      })
    }
    setScorePayload({})
  };

  const onCloseAIModal = () => {
    setAiAssistant(false);
  };

  const factModal = () => {
    setIsFactModalOpen(true);
  };
  const setOptionCriteriaProsCons = (key, value) => {

    if (value) {
      const found = userDecisions?.criteria?.find((e) => e?.id == value);

      setActiveCriteria({ id: found?.id, title: found?.title });
      setSelectedCriterias({ id: found?.id, title: found?.title });

      setCriteriaForChanging({
        options: isShortList
          ? criteriaForChanging?.options?.filter(
            (option) => option.shortlist === true
          )
          : criteriaForChanging?.options,
        showPopup: true,
        optionId: selectedOptionId,
        optionName: selectedOption?.name,
        decisionId: found?.decision,
        criteriaId: found?.id,
        optionCriteria: criteriaForChanging?.optionCriteria,
        criteriaName: found?.title,
        rubric: found?.rubric,
      });
    }
  };


  const handleFactsModal = (criteriaSelected, facts) => {
    setFacts(facts);
    // updateFacts({ facts }, selectedOptionCriteria?.id); // facts came here in array expected in string, have to change
    setIsFactModalOpen(false);
    setIsChange(false)
  };

  useEffect(() => {
    if (userDecisions && userDecisions.criteria)
      setActiveCriterias(
        userDecisions?.criteria
          ?.filter((c) => c.active)
          .sort((a, b) => a.id - b.id)
          .sort((a, b) => b.weight - a.weight)
      );
  }, [userDecisions]);

  const closeDrawer = () => {
    setDrawerClosed(true);
    onSave();
    setCriteriaForChanging({ showPopup: false });
  };

  const CloseIconClick = () => {
    setDrawerClosed(true);
    setCriteriaForChanging({ showPopup: false });
    setScoreData([])
    setCriteriaPayload([])
    setFactsPayload([])
  }

  return (
    <div className={classes.create_option_criteria_container}>
      <Drawer
        title="Drawer"
        placement="right"
        closeIcon={false}
        headerStyle={{ display: "none" }}
        bodyStyle={{ padding: "0" }}
        onClose={closeDrawer}
        width={588}
        open={true}
      >
        <div className={classes.drawer_header}>
          <div className={classes.nav_btns}>
            <button
              className={classes.arrow_btn}
              onClick={handleBack}
              disabled={isDisabledBack()}
            >
              <LeftIcon />
            </button>
            <button
              className={classes.arrow_btn}
              onClick={handleForward}
              disabled={isDisabledForward()}
            >
              <RightIcon />
            </button>
            <h5>{selectedOption?.name}</h5>
          </div>
          <div>
            <div className={classes.close_btn} onClick={CloseIconClick}>
              <CloseIcon />
            </div>
          </div>
        </div>
        <Spin tip="Loading" size="small" spinning={isLoading}>
        <div className={classes.drawer_body}>
          <div className={classes.criteria_container}>
            <div className={classes.title_container}>
              <span>Criteria</span>
              <div className={classes.title}>
                <h5>{selectedCriteria?.title}</h5>
              </div>
            </div>
            <div className={classes.score_and_weight_container}>
              <ScoreWrapper
                criteria={selectedCriteria}
                optionCriteria={selectedOptionCriteria}
                isShortList={isShortList}
                disabled={userDecisions?.is_sample || secure_token}
                setIsUpdateMe={setIsUpdateMe}
                isUpdateMe={isUpdateMe}
                setIsTrue={setIsTrue}
                isTrue={isTrue}
                activeCriterias={activeCriterias}
                setScorePayload={setScorePayload}
                setIsChange={setIsChange}
                setRubricsPayload={setRubricsPayload}
                filterScore={filterScore}
              />
              <div className={classes.weight_container}>
                <p>Weight</p>
                <div className={classes.weight}>
                  <span>{selectedCriteria?.weight}%</span>
                </div>
              </div>
            </div>
          </div>
          <CompareProCons
            activeOptionCriteria={selectedOptionCriteria}
            isShortList={isShortList}
            readOnly={userDecisions?.is_sample || secure_token}
            setAiAssistant={setAiAssistant}
            drawerClosed={drawerClosed}
            setConsPros={setConsPros}
            setIsChange={setIsChange}
            consProsContext={consProsContext}
            filterData={filterData}

          />

          {selectedOptionCriteria && (
            <>
              <div className={classes.heading_div}>
                <Notes
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  value={facts}
                  disabled={userDecisions?.is_sample || secure_token}
                  onChange={onFactsChangeHandle}
                  onClick={() => factModal()}
                  setIsChange={setIsChange}
                />
              </div>
            </>
          )}

          <div className={classes.button}>
            <button
              disabled={
                getDecisionDetailsLoading ||
                userDecisions?.is_sample ||
                secure_token ||
                isChange
              }
              onClick={(e) => onSave(e)}
            >
              SAVE
            </button>
          </div>
        </div>
        </Spin>
        {isFactModalOpen && (
          <FactsModal
            isEditable={true}
            optionCriteria={selectedOptionCriteria}
            criteria={userDecisions?.criteria?.filter((item) => item?.active)}
            handleApply={(selectCriteria, facts) =>
              handleFactsModal(selectCriteria, facts)
              
            }
            onClose={() => setIsFactModalOpen(false)}
            // selectedCriteria={selectCriteria?.id}
            selectedCriteria={selectedCriterias}
            optionId={activeOptionCriteria?.id}
            // onSelectedCriteriaChange={(name, value) => setSelectCriteria(value)}
            onSelectedCriteriaChange={setOptionCriteriaProsCons}
          />
        )}
        {aiAssistant && (
          <CompareAIAssistantModal
            criteria={userDecisions?.criteria?.filter((item) => item?.active)}
            // setOptionCriteriaProsCons={setOptionCriteriaProsCons}
            // optionId={selectedOptionCriteria?.id}
            optionId={activeOptionCriteria?.id}
            setOptionCriteriaProsCons={setOptionCriteriaProsCons}
            onClose={onCloseAIModal}
            // selectedOption={selectedOption}
            selectedCriteria={selectedCriterias}
            isDisabled={true}
            setConsPros={setConsPros}
            setIsChange={setIsChange}
            setConsProsContext={setConsProsContext}
          />
        )}
      </Drawer>
    </div>
  );

  // return (
  //   <div className={classes.bg}>
  //     <div className={classes.wrapper}>
  //       <div className={classes.header}>
  //         <span>
  //           {criteriaForChanging?.optionName} details for{" "}
  //           {criteriaForChanging?.criteriaName} criterion
  //         </span>
  //         {/* <span>
  //           Comments: {criteriaForChanging?.optionCriteria?.criteria?.title} for{" "}
  //           {criteriaForChanging?.optionName}
  //         </span> */}
  //         <button
  //           disabled={getDecisionDetailsLoading}
  //           onClick={(e) => {
  //             setCriteriaForChanging(false);
  //             e.stopPropagation();
  //             dispatch(SetPros(null));
  //             dispatch(SetCons(null));
  //           }}
  //           className={classes.close}
  //         >
  //           <CloseOutlined style={{ color: "white" }} />
  //         </button>
  //       </div>
  // <form className={classes.body} onSubmit={onSave}>
  //   {/* <EnhanceAI context={`${criteriaForChanging?.optionName} ${criteriaForChanging?.criteriaName}`}> */}
  //     <textarea
  //       value={inputValue}
  //       placeholder="Enter some facts about this option..."
  //       onChange={(e) => setInputValue(e.target.value)}
  //       ref={$textarea}
  //     />
  //   {/* </EnhanceAI> */}
  // </form>
  //       <div className={classes.compareWrapper}>
  //         <Compare
  //           activeOptionCriteria={criteriaForChanging?.optionCriteria}
  //           isShortList={isShortList}
  //         />
  //       </div>
  //       <hr />
  //       <div className={classes.button}>
  //         <button
  //           disabled={!inputValue || getDecisionDetailsLoading}
  //           onClick={(e) => onSave(e)}
  //         >
  //           SAVE
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export { CreateOptionCriteria };
