import React, { useEffect, useState, useRef } from "react";
import classes from "./scoringRubricModal.module.scss";
import { ReactComponent as CloseIcon } from "./close.svg";
import { Modal } from "antd";
import { AIScoringRubicModal } from "../AIAssistantModal/AIScoringRubicModal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  GetDecisionDetails,
  SetDecisionDetails,
} from "redux/DecisionMatrix/GetDecisionDetails/action";

const ScoringRubricModal = ({ criteria, onSave, onClose, optionCriteriaId, rubric }) => {
  const [isRubricModal, setIsRubricModal] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [activeCriterias, setActiveCriterias] = useState();
  const [textAreaVal, setTextAreaVal] = useState("");
  const [rubricTexLimitError, setRubricTexLimitError] = useState(false)
  // const dispatch = useDispatch();
  const { decisionId } = useParams();
  // useEffect(() => {
  //   if (!decisionId) return;
  //   dispatch(GetDecisionDetails(decisionId));
  //   return () => {
  //     dispatch(SetDecisionDetails(null));
  //   };
  // }, [decisionId]);
  
  useEffect(() => {
    if (!activeCriterias) return;
    let res = activeCriterias?.find((item) => item?.id === optionCriteriaId);
    setSelectedCriteria(res);
  }, [criteria, activeCriterias]);

  const $field = useRef(null);
  const { success: decision } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  useEffect(() => {
    const activeCriteria = decision?.criteria?.filter(
      (c) => c?.active && !c?.is_deleted
    );
    setActiveCriterias(activeCriteria);
  }, [decision?.criteria]);

  useEffect(() => {
    if ($field) $field.current.focus();
  }, []);
  useEffect(() => {
    setTextAreaVal(rubric ? rubric : selectedCriteria?.rubric);
  }, [selectedCriteria, rubric]);
  const handleTextArea = (e) => {
    if (e?.target?.value?.replaceAll("\n", "").length > 2000){
      setRubricTexLimitError(true)
    } else{
      setRubricTexLimitError(false)
    }
    setTextAreaVal(e?.target?.value);
  };
  const onRubricSaveHandle = (_, rubrics) => {
    setTextAreaVal(rubrics);
    setIsRubricModal(null);
  };
  const handleSave = () => {
    onSave(optionCriteriaId, textAreaVal);
  };
  return (
    <>
      <div className={classes.modalDiv}>
        <Modal
          open={true}
          footer={null}
          bodyStyle={{ padding: "0" }}
          closable={null}
        >
          <div className={classes.wrapper}>
            <div className={classes.header}>
              <span>Define Scoring Rubric</span>
              <button
                type="button"
                onClick={onClose}
                className={classes.close}
                title="Close"
              >
                <CloseIcon />
              </button>
            </div>
            <div className={classes.body}>
              {/*  <p>
              Set names for your each scoring value, name should include maximum
              10 characters.
            </p> */}
              <div className={classes.scoringValue}>
                <h3>Set names for your each scoring value.</h3>
                <button
                  className={classes.Aibtn}
                  onClick={() => setIsRubricModal(true)}
                >
                  Generate with AI
                </button>
              </div>

              <textarea
                placeholder="6 to 10-Excellent, 3 to 5 Good ..."
                value={textAreaVal || ""}
                onChange={(e) => handleTextArea(e)}
                ref={$field}
                style={{ borderColor: rubricTexLimitError ? '#f51e14' : null }}
              />
              {rubricTexLimitError && (<div style={{ color: '#f51e14', fontSize: 12 }}>Rubric cannot be greater than 2000 characters.</div>)}
            </div>

            <div className={classes.footer}>
              <button type="button" onClick={handleSave} disabled={rubricTexLimitError}> 
                SAVE
              </button>
            </div>
          </div>
        </Modal>
      </div>
      {isRubricModal && (
        <AIScoringRubicModal
          decisionID={decisionId}
          criteria={criteria}
          handleApply={onRubricSaveHandle}
          onClose={() => setIsRubricModal(false)}
          selectedCriteria={selectedCriteria}
          onSelectedCriteriaChange={(name, value) => {
            let res = activeCriterias?.find(
              (item) => item?.id === parseInt(value)
            );
            setSelectedCriteria({ id: res?.id, title: res?.title });
          }}
        />
      )}
    </>
  );
};

export { ScoringRubricModal };
