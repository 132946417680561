import styled, { css } from "styled-components";

export const Wrapper = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  min-height: 45px;

  &:hover {
    background-color: #fffffffa;
  }
`;

const sizes = [
  "24.60",
  "8.09",
  "7.26",
  "5.77",
  "5.77",
  "7.03",
  "8.40",
  "8.17",
  "6.63",
  "7.66",
  "7.77",
];

let nthChildStyles = "";

for (let i = 0; i < sizes.length; i++) {
  nthChildStyles += `
    &:nth-child(${i + 2}) {
      min-width: ${sizes[i]}%;
    }
  `;
}

export const Col = styled.li<{ $center?: boolean }>`
  display: flex;
  align-items: center;
  /* border-right: 1px solid var(--Neutral-200, #e5e5e5); */
  border-bottom: 1px solid var(--Neutral-200, #e5e5e5);
  padding: 0 12px;
  /* color: var(--Neutral-700, #404040); */
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  border: none;
  ${nthChildStyles};

  ${({ $center }) =>
    $center &&
    css`
      text-align: center;
      justify-content: center;
    `}

  &:nth-child(1) , &:nth-child(2) , &:nth-last-child(1) {
    width: 40px;
    min-width: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:nth-child(6) {
    width: 170px;
    min-width: 170px;
  }

  &:nth-child(3) {
    padding: 0 12px 0 0;
    min-width: calc(32% - 6px);
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #404040;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
`;

export const Dragger = styled.span<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  width: 100%;
  height: 100%;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const AddButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const iconStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 0;
`;

/* export const Delete = styled.button`
  margin-right: 8px;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  ${iconStyles};
`; */

export const InputGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  // padding: 12px 0 12px 12px;
  padding: 0 12px 0 0;
  // gap: 4px;

  &:hover {
    .action-items {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const NameContent = styled.div<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${({ $disabled }) =>
    $disabled
      ? css``
      : css`
          &:hover {
            button {
              opacity: 1;
              visibility: visible;
            }
          }
        `}
`;

export const NameContainer = styled.div<{ $collapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px 0px 14px 12px;

  ${({ $collapsed }) =>
    $collapsed &&
    css`
      padding-bottom: 14px;
    `}
`;

export const NameWrapper = styled.div`
  flex: 1;
  word-break: break-word;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  input {
    height: 100%;
    padding: 13px 12px;
    border-radius: 0;
    margin-right: 12px;
  }

  span {
    display: block;
    cursor: default;
    transition: padding-bottom 0.3s;
  }
`;

export const NoteWrapper = styled.div`
  div {
    padding: 8px 0 12px 12px;
  }
`;

export const AddNote = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: none;
  border: none;
  color: var(--primary-500-main, #1271a6);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  padding: 0;
`;

export const Toggle = styled.button<{ $active: boolean }>`
  ${iconStyles};
  transform: rotate(90deg);
  transition: transform 0.3s;

  ${({ $active }) =>
    $active &&
    css`
      transform: rotate(0deg);
    `}
`;

/* export const UserPicture= styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1.5px solid var(--Generic-White, #FFF);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.15), 0px 1px 2px -1px rgba(16, 24, 40, 0.15);
`; */
