import React, { useEffect, useRef, useState, useMemo } from "react";

import { Link, useNavigate } from "react-router-dom";

// Components
import { AppLayout } from "components";
import CriteriaTag from "../../components/CriteriaTag";
import Owner from "../ProjectPlanner/CreatePlanModal/Owner";
import AppInput from "../../components/AppInput";
import { getPath } from "../../components/AppSwitcher";
import InviteUserModal from "../PlanDetails/Resources/components/InviteUserModal";
import { AppLoading } from "components/AppLoading";
import Assistant from "../../components/Assistant";

// Styles
import styles from "./styles.module.scss";

// Icons
import { ReactComponent as BackIcon } from "assets/svg/BackPrimary.svg";
import { ReactComponent as EditGray } from "assets/svg/editGray.svg";
import { ReactComponent as PlusBlue } from "assets/svg/plusBlue.svg";
import { ReactComponent as MenuDots } from "assets/svg/menudots.svg";
import { ReactComponent as File } from "assets/svg/file.svg";
import { MailOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactComponent as ArrowDownGray } from "assets/svg/arrowDownGray.svg";
import { ReactComponent as Cross } from "assets/svg/close.svg";
import { ReactComponent as DeleteSmall } from "assets/svg/deleteSmall.svg";
import { ReactComponent as AiStarIcon } from "assets/svg/aiStar.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as AIAssistant } from "assets/svg/aiAssistant.svg";

//  Redux
import { useDispatch, useSelector } from "react-redux";
import { getProjectOwners } from "modules/actions/UserActions";
import {
  createProjectPlan,
  getGaolList,
  getTools,
  updateFile,
  UpdateTutorial,
} from "../../modules/actions/PlanActions";
import {
  getProjectUsers,
  inviteOwner,
} from "../../modules/actions/UserActions";
import { websocketConnection } from "utils/websocket";

// Antd
import {
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Modal,
  Popover,
  Row,
  Select,
  Tag,
  Tooltip,
} from "antd";

// Moment
import moment from "moment";

// Utils
import {
  get_url_title,
  truncateEmail,
  validateEmail,
} from "../../utils/utility";
import Stars from "../../assets/svg/stars.svg";

import { COLORS } from "constants/colors";

// React Toastify
import { toast } from "react-toastify";
import { generateUniqueId } from "../../utils/helper";
import GoalSearch from "../../components/GoalSearch";

const CreateProjectPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const goalRef = useRef(null);

  const { gpsURL } = getPath();

  const [value, setValue] = useState("");
  const [endDate, setEndDate] = useState();
  const [nameError, setNameError] = useState(false);
  const [dateError, setDateError] = useState({
    start: false,
    end: false,
  });

  const [criteria_tags, setCriteriaTags] = useState([]);
  const [isAssistant, setIsAssistant] = useState(false);
  const [AssistantType, setAssistantType] = useState(null);
  const [isAssistantLoading, setIsAssistantLoading] = useState(false);
  const [isAssistantSocketData, setIsAssistantSocketData] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState(null);

  const [toolTipPopover, setToolTipPopover] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const {
    projectUserLoading,
    users,
    loading: inviteLoading,
    projectUser,
  } = useSelector((state) => state.user.projectOwners);

  const userID = user?.data?.id;

  const userEmail = user?.data?.email ?? null;

  const findUser = projectUser?.find((user) => user?.email == userEmail);

  const { loading, loadingUplaod, uploadFile, tools, allGoalList } =
    useSelector((state) => state.plan.plan);

  const [isVerifiedEmail, setIsVerifiedEmail] = useState(false);
  const [isGoalSelect, setIsGoalSelect] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [isEditTools, setIsEditTools] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [editTool, setEditTool] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [initialUsers, setInitialUsers] = useState([]);
  const [registrationLoading, setRegistrationLoading] = useState(false);

  const [emailToInvite, setEmailToInvite] = useState(null);
  const [inviteEmail, setInviteEmail] = useState(null);
  const [isAIPlanGenerating, setIsAIPlanGenerating] = useState(false);
  const [searchGoal, setSearchGoal] = useState("");

  const [state, setState] = useState({
    purpose: "",
    name: "",
    duration: "",
    description: "",
    start_line: null,
    dead_line: null,
    objective: "",
    goalName: "",
    idea: "",
    invited_owner: user?.data?.email,
    ownerText: "",
    inviteModal: false,
    inviteProjectModal: false,
    inviteProjectEmail: false,
    notes: [],
    files: [],
    peoplePop: false,
    toolsAdded: [],
    selectedUsers: [],
    selectedTools: [],
    allTools: tools || [],
    allUsers: users || [],
    newTools: [],
    toolPop: false,
    searchTool: "",
    searchUser: "",
    disabledToolButton: true,
    disabledPeopleButton: true,
  });

  const {
    purpose,
    name,
    objective,
    duration,
    start_line,
    dead_line,
    idea,
    notes,
    invited_owner,
    ownerText,
    inviteProjectModal,
    inviteProjectEmail,
    newTools,
    files,
    peoplePop,
    selectedUsers,
    toolsAdded,
    selectedTools,
    searchTool,
    allTools,
    allUsers,
    toolPop,
    searchUser,
    disabledToolButton,
    disabledPeopleButton,
  } = state;

  const items = (file) => [
    {
      label: "View",
      key: "1",
      onClick: (e) => {
        window.open(file?.url, "_blank");
      },
    },
    {
      label: "Remove",
      key: "0",
      onClick: (e) => {
        const removefile = files.filter((item) => item?.id !== file?.id);
        setState((pre) => ({ ...pre, files: removefile }));
      },
      danger: true,
    },
  ];

  const handleChangeState = (key, value) => {
    let input = value;

    if (input?.startsWith(" ")) {
      input = input.trimStart();
    }
    setState((pre) => ({ ...pre, [key]: input }));
  };

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const removeAlready = () => {
    const removed = allUsers?.filter(
      (elem) => !selectedUsers?.find(({ id }) => elem.id === id)
    );
    return removed;
  };

  const handleAddNote = () => {
    if (value) {
      setState((pre) => ({
        ...pre,
        notes: [
          ...pre.notes,
          {
            context: value,
            updated_at: new Date(),
          },
        ],
      }));
      setValue("");
    }
  };

  const handleInvite = (e) => {
    if (user?.data?.email === searchUser) {
      toast.error("You cannot invite yourself");
      return;
    }
    setEmailToInvite(searchUser);
    setInviteEmail(searchUser);
    setState((pre) => ({ ...pre, searchUser: "" }));
    handleChange("peoplePop", false);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const payload = new FormData();
      payload.append("url", file);
      dispatch(updateFile(payload));
    }
    fileInputRef.current.value = null;
  };

  const filtered = (key, value, isTool) => {
    let newValue = value;

    if (newValue.length > 60) newValue = newValue.substring(0, 60);

    handleChange(key, newValue);

    const data = isTool ? tools : users;
    const selectedState = isTool ? "allTools" : "allUsers";

    if (newValue) {
      var filtered = data?.filter((entry) =>
        entry[!isTool ? "email" : "name"]
          ?.toLowerCase()
          ?.includes(newValue?.toLowerCase())
      );

      handleChange(selectedState, filtered);
    } else {
      handleChange(selectedState, data);
    }
  };

  const handleAddSelectedTools = (tool) => {
    handleChange("disabledToolButton", false);
    if (selectedTools?.some((e) => e?.id === tool?.id)) {
      const removed = selectedTools?.filter((e) => e?.id !== tool?.id);
      handleChange("selectedTools", removed);
    } else {
      handleChange("selectedTools", [...selectedTools, tool]);
    }
  };

  const removeToolsAlready = () => {
    const removed = allTools?.filter(
      (elem) => !selectedTools?.find(({ id }) => elem.id === id)
    );
    return removed;
  };

  const checkSameTool = (value) => {
    const found = selectedTools?.some((elem) => elem?.name === value);
    return found;
  };

  const handleAddTool = () => {
    setState((pre) => ({ ...pre, toolsAdded: selectedTools }));
    handleChange("toolPop", false);
  };

  const handleCloseEditModel = () => {
    setIsEditTools(false);
    handleChange("toolPop", false);
    setEditTool(null);
    setIsVerifiedEmail(false);
    setInviteEmail(null);
    setRegistrationLoading(false);
  };

  const handleDelete = (tool) => {
    const newTools = editTool?.filter((item) => item?.id !== tool?.id);
    setEditTool(newTools);
  };

  const handleEdit = (value, tool) => {
    const newTools = editTool?.map((item) => {
      if (item?.id === tool?.id) {
        return { ...item, name: value };
      }
      return item;
    });

    setEditTool(newTools);
  };

  const handleAddSelectedUsers = (owner) => {
    if (owner?.email === user?.data?.email) {
      toast.error("You cannot add yourself");
      return;
    }

    handleChange("disabledPeopleButton", false);
    if (selectedUsers?.some((e) => e?.id === owner?.id)) {
      const removed = selectedUsers?.filter((e) => e?.id !== owner?.id);
      handleChange("selectedUsers", removed);
    } else {
      handleChange("selectedUsers", [...selectedUsers, owner]);
    }
  };

  const handleUpdateTool = () => {
    setState((pre) => ({ ...pre, toolsAdded: editTool }));
    setIsEditTools(false);
  };

  const handleAddPeople = () => {
    setInitialUsers(selectedUsers);
    handleChange("peoplePop", false);
  };

  const handleAddNewTool = () => {
    const tool = {
      id: generateUniqueId(),
      isNew: true,
      name: searchTool,
    };
    handleChange("newTools", [...newTools, tool]);
    handleChange("selectedTools", [...selectedTools, tool]);
    setState((pre) => ({ ...pre, searchTool: "" }));
    handleChange("allTools", tools);
    handleChange("disabledToolButton", false);
  };

  const handleOwnerModal = () => {
    handleChange("inviteProjectModal", false);
  };

  const handleInviteProject = (e) => {
    const payload = { invited_owner: inviteProjectEmail };
    dispatch(inviteOwner(payload, handleOwnerModal));
  };

  const checkAnyChange = () => {
    if (!selectedUsers || !initialUsers) return;

    const initialUserIDs = initialUsers.map((user) => user.id);
    const selectedUserIDs = selectedUsers.map((user) => user.id);

    const hasChanged =
      initialUserIDs.length !== selectedUserIDs.length ||
      initialUserIDs.some((id) => !selectedUserIDs.includes(id)) ||
      selectedUserIDs.some((id) => !initialUserIDs.includes(id));

    setHasChanges(hasChanged);
  };

  const handleInviteUser = () => {
    setRegistrationLoading(true);
    const payload = {
      invited_owner: inviteEmail,
      // project_id: planDetails?.id,
    };
    const qs = ``;
    dispatch(inviteOwner(payload, handleCloseEditModel, qs));
  };

  const handleNavigationAfterAIPlan = (response) => {
    navigate(
      `/project-planner/plan-details/${response?.project_plan?.id}?isAI=true`
    );
    localStorage.setItem("isAIGenerate", "true");
  };
  const handleCanel = () => {
    navigate("/project-planner");
  };

  const handleCreate = (isAiGenerated = false) => {
    if (!name || !start_line || !dead_line) {
      setNameError(true);

      setDateError({
        start: !start_line,
        end: !dead_line,
      });
    } else {
      setNameError(false);

      setDateError({
        start: false,
        end: false,
      });

      if (isAiGenerated) {
        setIsAIPlanGenerating(true);
      }

      let payload = {
        project_plan: {
          name: name,
          goal_public_id: selectedGoal?.id || "",
          objective: objective,
          purpose: purpose,
          idea: idea,
          duration: duration,
          invited_owner: invited_owner,
          start_line: moment(start_line).format("YYYY-MM-DD") || "",
          dead_line: moment(dead_line).format("YYYY-MM-DD") || "",
        },
        resources: {
          people: initialUsers?.map((user) => user.id),
          files: files?.map((file) => file.id),
          tools: newTools?.map((tool) => {
            return {
              name: tool.name,
            };
          }),
          tools_ids: toolsAdded
            ?.map((tool) => !tool?.isNew && tool.id)
            .filter(Boolean),
        },
        criteria_tags: criteria_tags?.map((tag) => {
          return {
            name: tag?.tag_name,
            weight: tag?.weight,
            color: tag?.color,
          };
        }),
        notes: notes?.map((note) => {
          return {
            context: note.context,
          };
        }),
      };

      if (isAiGenerated) {
        payload = {
          ...payload,
          project_plan: { ...payload.project_plan, ai_generation: true },
        };
      }

      dispatch(
        createProjectPlan(
          payload,
          isAiGenerated ? handleNavigationAfterAIPlan : handleCanel
        )
      );
    }
  };

  useEffect(() => {
    if (uploadFile && uploadFile?.url && !loadingUplaod) {
      setState((pre) => ({ ...pre, files: [...pre.files, uploadFile] }));
    }
  }, [uploadFile]);

  useEffect(() => {
    dispatch(getProjectOwners(""));
    dispatch(getTools());
    dispatch(getGaolList());
    dispatch(getProjectUsers());
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (goalRef.current && !goalRef.current.contains(event.target)) {
        setIsGoalSelect(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [goalRef]);

  useEffect(() => {
    if (!peoplePop && !toolPop) {
      handleChange("searchUser", "");
      handleChange("searchTool", "");
    }

    if (users) {
      handleChange("allUsers", users);
      handleChange("allTools", tools);
    }
  }, [users, tools, peoplePop, toolPop]);

  const handleSocket = (type) => {
    setAssistantType(type);
    setIsAssistantLoading(true);
    setIsAssistant(true);
    websocketConnection.send(
      JSON.stringify({
        endpoint:
          type === "purpose"
            ? "generate_project_purpose"
            : type === "objective"
            ? "generate_project_objective"
            : "generate_project_brainstorm",
        name: name || "",
        goal: selectedGoal?.id || "",
        objective: objective || "",
        description: "description",
        brainstorm: idea || "",
      })
    );
  };

  useEffect(() => {
    if (selectedUsers) {
      checkAnyChange();
    }
  }, [selectedUsers]);

  const isPlanGenerationLoading = useMemo(() => {
    if (loading && !isAIPlanGenerating) {
      return true;
    }
    return false;
  }, [isAIPlanGenerating, loading]);

  const isAIPlanGenerationLoading = useMemo(() => {
    if (loading && isAIPlanGenerating) {
      return true;
    }
    return false;
  }, [isAIPlanGenerating, loading]);

  const handleMessage = (message) => {
    setIsAssistantLoading(false);
    let res = JSON?.parse(message);
    if (res?.ping || res?.pong) return;
    setIsAssistantSocketData(res);
  };

  useEffect(() => {
    websocketConnection?.setMessageCallback(handleMessage);

    return () => {
      websocketConnection?.setMessageCallback(null);
    };
  }, []);

  const tutorialProgress =
    findUser && findUser?.tutorial_progress?.step_1 === "done" ? false : true;

  const updateTutorial = () => {
    if (!tutorialProgress) return;
    setToolTipPopover(false);
    const payload = {
      tutorial_progress: {
        step_1: "done",
      },
    };
    dispatch(UpdateTutorial(userID, payload));
  };

  useEffect(() => {
    if (selectedDescription) {
      if (AssistantType === "objective") {
        handleChangeState("objective", selectedDescription || "");
      } else if (AssistantType === "purpose") {
        handleChangeState("purpose", selectedDescription || "");
      } else if (AssistantType === "brainstorm") {
        handleChangeState("idea", selectedDescription || "");
      }
    }
  }, [selectedDescription]);

  useMemo(() => {
    setToolTipPopover(tutorialProgress);
  }, [tutorialProgress]);

  const filterGoalList = allGoalList?.filter((goal) =>
    goal?.goal_name.toLowerCase().includes(searchGoal?.toLowerCase())
  );

  return (
    <>
      <Assistant
        type={AssistantType}
        active={isAssistant}
        setActive={() => setIsAssistant(false)}
        loading={isAssistantLoading}
        handleSocket={handleSocket}
        data={isAssistantSocketData}
        setSelectedDescription={setSelectedDescription}
      />

      <InviteUserModal
        isActive={!!emailToInvite}
        emailToInvite={emailToInvite ?? ""}
        setIsVerifiedEmail={setIsVerifiedEmail}
        onClose={() => setEmailToInvite(null)}
        handleChange={handleChange}
        selectedUsers={selectedUsers}
        setHasChanges={setHasChanges}
        isNewUser={isNewUser}
        setIsNewUser={setIsNewUser}
        handleChangeLocal={handleChange}
        isNewUserAdd={inviteProjectModal ? false : true}
      />

      {projectUserLoading ? (
        <AppLoading loading={true} />
      ) : (
        <AppLayout>
          <div className={styles.planHeader}>
            <Link className={styles.back} to="/project-planner/">
              <BackIcon
                style={{
                  cursor: "pointer",
                  marginTop: "-2px",
                }}
              />
            </Link>

            <h3>New Project</h3>
          </div>

          <div className={styles.projectPlanDetails}>
            <div className={styles.projectPlanDetails_top_wrapper}>
              <div className={styles.projectPlanDetails_form}>
                <div className={styles.projectPlanDetails_header}>
                  <h2>Project Plan Details</h2>
                </div>

                <div className={styles.form}>
                  <div className={styles.form_left}>
                    <div className={styles.inputGroup}>
                      <label className={styles.label}>Project Name *</label>
                      <div className={styles.inputWrapper}>
                        <input
                          className={styles.input}
                          value={name}
                          name={"name"}
                          placeholder="Text"
                          onChange={(event) => {
                            let input = event.target.value;

                            if (input.length > 255) {
                              input = input.slice(0, 255);
                            }

                            handleChangeState(event.target.name, input);
                          }}
                        />

                        {nameError && (
                          <label className={styles.error}>
                            Project Name is required
                          </label>
                        )}
                      </div>
                    </div>

                    <div className={styles.inputGroup}>
                      <label className={styles.label}>Goal (Optional)</label>

                      <div className={styles.goal_input_wrapper}>
                        <div
                          ref={goalRef}
                          className={styles.goal_input}
                          onClick={() => setIsGoalSelect(!isGoalSelect)}
                          style={{
                            borderLeft: selectedGoal
                              ? `4px solid ${selectedGoal?.color || "#A3A3A3"}`
                              : "1px solid #e5e5e5",
                          }}
                        >
                          {selectedGoal ? (
                            <div
                              className={styles.goal_input_selected}
                              style={{
                                borderLeftColor:
                                  selectedGoal?.color || "#A3A3A3",
                              }}
                            >
                              <h3>{selectedGoal?.goal_name || "No Name"}</h3>
                            </div>
                          ) : (
                            "Select goal"
                          )}

                          <ArrowDownGray />
                        </div>

                        <GoalSearch
                          allGoalList={allGoalList}
                          goalRef={goalRef}
                          isGoalSelect={isGoalSelect}
                          setIsGoalSelect={setIsGoalSelect}
                          selectedGoal={selectedGoal}
                          setSelectedGoal={setSelectedGoal}
                          onwer={true}
                        />
                      </div>
                    </div>

                    <div className={styles.inputGroup}>
                      <label className={styles.label}>Objective</label>
                      <div className={styles.inputWrapper}>
                        <div className={styles.Ai_input}>
                          <input
                            className={styles.input}
                            value={objective || ""}
                            name={"objective"}
                            onChange={(event) => {
                              let input = event.target.value;

                              if (input.length > 255) {
                                input = input.slice(0, 255);
                              }

                              handleChangeState(event.target.name, input);
                            }}
                            placeholder="Enter your objective"
                          />
                          <div className={styles.ai_Icon}>
                            <Tooltip
                              title={
                                !name
                                  ? "Please enter project name first"
                                  : "Suggest objective"
                              }
                              overlayClassName="ai_tooltip"
                            >
                              <AiStarIcon
                                onClick={() =>
                                  name && handleSocket("objective")
                                }
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.inputGroup}>
                      <label className={styles.label}>Purpose</label>
                      <div className={styles.inputWrapper}>
                        <div className={styles.Ai_input}>
                          <input
                            className={styles.input}
                            value={purpose || ""}
                            name={"purpose"}
                            onChange={(event) => {
                              let input = event.target.value;

                              if (input.length > 255) {
                                input = input.slice(0, 255);
                              }

                              handleChangeState(event.target.name, input);
                            }}
                            placeholder="Enter your purpose"
                          />
                          <div
                            className={styles.ai_Icon}
                            style={{
                              opacity: toolTipPopover && 1,
                              visibility: toolTipPopover && "visible",
                            }}
                          >
                            {toolTipPopover ? (
                              <Popover
                                content={
                                  <>
                                    <div className={styles.ai_popover}>
                                      <div className={styles.ai_popoverHeader}>
                                        <AIAssistant />
                                      </div>
                                      <div className={styles.ai_popoverContent}>
                                        <h3>Suggest a purpose</h3>
                                        <h6>
                                          Tap the icon to generate purpose
                                          suggestions.
                                        </h6>

                                        <div
                                          className={styles.ai_popoverAction}
                                        >
                                          <button onClick={updateTutorial}>
                                            Got It
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                }
                                open={toolTipPopover}
                                overlayClassName="ai_popover"
                                placement="right"
                                showArrow={true}
                                onOpenChange={(visible) => {
                                  if (!visible) {
                                    updateTutorial();
                                  }
                                  setToolTipPopover(visible);
                                }}
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                              >
                                <Tooltip
                                  title={
                                    !name
                                      ? "Please enter project name first"
                                      : "Suggest purposes"
                                  }
                                  overlayClassName="ai_tooltip"
                                >
                                  <AiStarIcon
                                    onClick={() =>
                                      name && handleSocket("purpose")
                                    }
                                  />
                                </Tooltip>
                              </Popover>
                            ) : (
                              <Tooltip
                                title={
                                  !name
                                    ? "Please enter project name first"
                                    : "Suggest purposes"
                                }
                                overlayClassName="ai_tooltip"
                              >
                                <AiStarIcon
                                  onClick={() =>
                                    name && handleSocket("purpose")
                                  }
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.inputGroup}>
                      <label className={styles.label}>Brainstorm Ideas</label>

                      <div className={styles.inputWrapper}>
                        <div className={styles.Ai_input}>
                          <input
                            className={styles.input}
                            value={idea || ""}
                            name={"idea"}
                            onChange={(value) => {
                              let input = value.target.value;

                              if (input.length > 500) {
                                input = input.slice(0, 500);
                              }

                              handleChangeState(value.target.name, input);
                            }}
                            placeholder="Enter your ideas"
                          />
                          <div className={styles.ai_Icon}>
                            <Tooltip
                              title={
                                !name
                                  ? "Please enter project name first"
                                  : "Suggest Brainstorm Ideas"
                              }
                              overlayClassName="ai_tooltip"
                            >
                              <AiStarIcon
                                onClick={() =>
                                  name && handleSocket("brainstorm")
                                }
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.form_right}>
                    <div
                      className={styles.inputGroup}
                      style={{
                        gap: 30,
                      }}
                    >
                      <label className={styles.label}>Project Owner</label>

                      <Select
                        className={
                          invited_owner
                            ? "fullSelect PlanSelectActive"
                            : "fullSelect PlanSelect"
                        }
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        notFoundContent={
                          validateEmail(ownerText) ? (
                            <Button
                              type="primary"
                              onClick={() => {
                                handleChange("inviteProjectModal", true);
                                handleChange("inviteProjectEmail", ownerText);
                                handleChange("invited_owner", ownerText);
                              }}
                            >
                              Invite {truncateEmail(ownerText)}
                            </Button>
                          ) : (
                            "No results. Enter email to invite"
                          )
                        }
                        onSearch={(e) => handleChange("ownerText", e)}
                        placeholder="Select an owner"
                        value={truncateEmail(invited_owner)}
                        onChange={(value) =>
                          handleChange("invited_owner", value)
                        }
                        dropdownRender={(menu) => <Owner menu={menu} />}
                      >
                        {users?.map((owner, index) => (
                          <Option key={index} value={owner?.email}>
                            {owner?.email}
                          </Option>
                        ))}
                      </Select>
                    </div>

                    <div className={styles.inputGroup}>
                      <label className={styles.label}>Start Date *</label>
                      <div className={styles.datePickerWrapper}>
                        <DatePicker
                          value={start_line}
                          name={"start_line"}
                          format={"MM/DD/YYYY"}
                          clearIcon={false}
                          style={{ width: "100%" }}
                          onChange={(date) => handleChange("start_line", date)}
                          disabledDate={(current) =>
                            current && current > moment(dead_line)
                          }
                          placeholder="MM.DD.YYYY"
                          className="projectDetail-datepicker"
                          getPopupContainer={(trigger) => trigger.parentElement}
                        />

                        {dateError.start && (
                          <label className={styles.error}>
                            Start date is required
                          </label>
                        )}
                      </div>
                    </div>

                    <div className={styles.inputGroup}>
                      <label className={styles.label}>End Date *</label>
                      <div className={styles.datePickerWrapper}>
                        <DatePicker
                          clearIcon={false}
                          format={"MM/DD/YYYY"}
                          value={dead_line ? dead_line : endDate}
                          disabledDate={(current) =>
                            current && current < moment(start_line)
                          }
                          style={{ width: "100%" }}
                          name={"dead_line"}
                          onChange={(date) => handleChange("dead_line", date)}
                          placeholder="MM/DD/YYYY "
                          className="projectDetail-datepicker"
                          getPopupContainer={(trigger) => trigger.parentElement}
                        />

                        {dateError.end && (
                          <label className={styles.error}>
                            End date is required
                          </label>
                        )}
                      </div>
                    </div>

                    <div className={styles.duration}>
                      <label className={styles.label}>Duration</label>

                      <h6>
                        {moment(dead_line).diff(moment(start_line), "days") > 0
                          ? moment(dead_line).diff(moment(start_line), "days") +
                            " days"
                          : "--"}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <CriteriaTag
                criteria_tags={criteria_tags}
                setCriteriaTags={setCriteriaTags}
              />
            </div>

            <div className={styles.projectPlanDetails_bottom_wrapper}>
              <div className={styles.projectPlanDetails_form}>
                <div className={styles.projectPlanDetails_header}>
                  <h2>Resources</h2>
                </div>

                <div className={styles.form}>
                  <div className={styles.form_left}>
                    <div className={styles.collaborators}>
                      <div className={styles.collaborators_heading}>
                        <h3 className={styles.heading}>Collaborators</h3>

                        <Popover
                          content={
                            <div
                              style={{
                                width: "380px",
                              }}
                            >
                              <div className="space-between mb-1 text_bold font-16">
                                <div>Collaborators</div>
                                <CloseOutlined
                                  onClick={() =>
                                    handleChange("peoplePop", false)
                                  }
                                />
                              </div>
                              <div className="peopleHeight">
                                {selectedUsers.length ? (
                                  selectedUsers?.map((people, index) => (
                                    <Row
                                      className="mt-1"
                                      align="middle"
                                      justify="space-between"
                                      key={people?.id}
                                    >
                                      <Row align="middle">
                                        <div key={index} className="greyBox">
                                          {people?.email?.substring(0, 2)}
                                        </div>
                                        <div>{people?.email}</div>
                                      </Row>
                                      <Button
                                        onClick={() =>
                                          handleAddSelectedUsers(people)
                                        }
                                        color={COLORS.primary}
                                        className={"b-1 addedButton"}
                                      >
                                        ADDED <CloseOutlined />
                                      </Button>
                                    </Row>
                                  ))
                                ) : (
                                  <div className="empty-div">
                                    No collaborators added yet
                                  </div>
                                )}
                              </div>
                              <Divider
                                style={{
                                  marginTop: 8,
                                  marginBottom: 8,
                                }}
                              />
                              <AppInput
                                label={"Add collaborator"}
                                value={searchUser}
                                name={"searchUser"}
                                onChange={filtered}
                                placeholder="Choose a collaborator"
                                height={36}
                              />
                              <div className="peopleHeight mt-1">
                                {removeAlready()?.length === 0 &&
                                  searchUser !== "" && (
                                    <Row
                                      className="mt-1 mb-1"
                                      align="middle"
                                      justify="space-between"
                                    >
                                      <Button
                                        onClick={handleInvite}
                                        color={COLORS.primary}
                                        type="primary"
                                        disabled={
                                          searchUser === user?.data?.email ||
                                          !validateEmail(searchUser)
                                        }
                                        style={{ color: COLORS.white }}
                                        className={"b-1 addButton"}
                                      >
                                        <PlusOutlined
                                          style={{ marginTop: 2 }}
                                        />{" "}
                                        {searchUser === user?.data?.email
                                          ? "You cannot invite yourself"
                                          : validateEmail(searchUser)
                                          ? "Invite " + searchUser
                                          : "No results. Enter email to invite"}
                                      </Button>
                                    </Row>
                                  )}

                                {removeAlready()?.map((owner) => (
                                  <Row
                                    className="mt-1 mb-1"
                                    align="middle"
                                    justify="space-between"
                                    key={owner?.id}
                                  >
                                    <Row align="middle">
                                      <div className="greyBox">
                                        {owner?.email?.substring(0, 2)}
                                      </div>
                                      <div>{owner?.email}</div>
                                    </Row>
                                    <Button
                                      onClick={() =>
                                        handleAddSelectedUsers(owner)
                                      }
                                      color={COLORS.primary}
                                      type="primary"
                                      className={"b-1 addButton"}
                                    >
                                      <PlusOutlined style={{ marginTop: 2 }} />
                                      ADD
                                    </Button>
                                  </Row>
                                ))}
                              </div>
                              <Divider className="width110 mb-2 mt-2" />
                              <Row justify="end">
                                <Button
                                  type="primary"
                                  disabled={disabledPeopleButton || !hasChanges}
                                  onClick={handleAddPeople}
                                  className="createPlanBtn"
                                  style={{
                                    height: 36,
                                  }}
                                >
                                  {"Save"}
                                </Button>
                              </Row>
                            </div>
                          }
                          trigger="click"
                          getPopupContainer={(trigger) => trigger.parentElement}
                          placement="bottomRight"
                          open={peoplePop}
                          onOpenChange={(value) => {
                            setState((pre) => ({
                              ...pre,
                              selectedUsers: initialUsers,
                            }));
                            handleChange("peoplePop", value);
                            setHasChanges(false);
                          }}
                        >
                          <button
                            className={styles.actionButton}
                            onClick={() => {
                              if (selectedUsers.length > 0) {
                                handleChange("disabledPeopleButton", false);
                              }
                              handleChange("peoplePop", true);
                            }}
                          >
                            <PlusBlue />
                            Add
                          </button>
                        </Popover>
                      </div>

                      <div className={styles.collaborators_list}>
                        {initialUsers?.length ? (
                          initialUsers?.map((people, index) => (
                            <div
                              className={styles.collaborators_avatar}
                              key={index}
                            >
                              {people?.email?.substring(0, 2)}
                            </div>
                          ))
                        ) : (
                          <div className={styles.nodataAdd}>
                            No collaborators invited
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.techStack}>
                      <div className={styles.techStack_heading}>
                        <h3 className={styles.heading}>Tech Stack</h3>

                        <Popover
                          content={
                            <div style={{ width: "380px", maxWidth: 480 }}>
                              <div className="space-between text_500 font-14 text_blue_dark mb-2">
                                <div>Add Tool</div>
                                <CloseOutlined
                                  onClick={() => handleChange("toolPop", false)}
                                />
                              </div>
                              <div className="peopleHeight mb-2">
                                <div className="tagsRow">
                                  {selectedTools
                                    ?.filter((tool) => tool && tool.id)
                                    ?.map((tool, index) => {
                                      return (
                                        <Tag
                                          key={index}
                                          closable
                                          onClose={(e) => {
                                            e.preventDefault();
                                            handleAddSelectedTools(tool);
                                          }}
                                          color={COLORS.toolBG}
                                          className={"text_tag mr-0"}
                                        >
                                          {tool?.name}
                                        </Tag>
                                      );
                                    })}
                                </div>
                              </div>
                              <div className="font-14 mb-1">Tool Name</div>
                              <AppInput
                                value={searchTool}
                                name="searchTool"
                                onChange={(key, value) =>
                                  filtered(key, value, true)
                                }
                                placeholder="e. g. Laptop, Windows"
                              />
                              <div
                                className="peopleHeight"
                                style={{
                                  marginTop: 10,
                                }}
                              >
                                {removeToolsAlready()?.length === 0 &&
                                  searchTool !== "" && (
                                    <Row
                                      className="mt-1 mb-1"
                                      align="middle"
                                      justify="space-between"
                                    >
                                      <Button
                                        onClick={handleAddNewTool}
                                        color={COLORS.primary}
                                        type="primary"
                                        disabled={checkSameTool(searchTool)}
                                        style={{ color: COLORS.white }}
                                        className={"b-1 addButton"}
                                      >
                                        <PlusOutlined
                                          style={{ marginTop: 2 }}
                                        />
                                        {checkSameTool(searchTool)
                                          ? "You already have this tool"
                                          : 'Add New Tool "' + searchTool + '"'}
                                      </Button>
                                    </Row>
                                  )}
                                {removeToolsAlready()?.map((tool, index) => (
                                  <Row
                                    key={index}
                                    align="middle"
                                    justify="space-between"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      gap: 4,
                                      margin: "8px 0px ",
                                    }}
                                  >
                                    <Row
                                      align="middle"
                                      className="text-oneline"
                                      style={{
                                        maxWidth: 280,
                                      }}
                                    >
                                      <div>{tool?.name}</div>
                                    </Row>
                                    <Button
                                      onClick={() =>
                                        handleAddSelectedTools(tool)
                                      }
                                      color={COLORS.primary}
                                      type="primary"
                                      className={"b-1 addButton"}
                                    >
                                      <PlusOutlined style={{ marginTop: 2 }} />{" "}
                                      ADD
                                    </Button>
                                  </Row>
                                ))}
                              </div>
                              <Divider
                                className="width110"
                                style={{
                                  margin: "10px 0px",
                                }}
                              />
                              <Row justify="end">
                                <Button
                                  type="primary"
                                  disabled={disabledToolButton}
                                  onClick={handleAddTool}
                                  className="createPlanBtn"
                                >
                                  {"Save"}
                                </Button>
                              </Row>
                            </div>
                          }
                          trigger="click"
                          overlayClassName="toolPop"
                          placement="bottomLeft"
                          open={toolPop}
                          onOpenChange={(value) => {
                            handleChange("toolPop", value);
                            handleChange("searchTool", "");
                            handleChange("selectedTools", toolsAdded);
                          }}
                          getPopupContainer={(trigger) => trigger.parentElement}
                          visible={toolPop}
                        >
                          <Row align={"middle"} className={styles.actions}>
                            <button
                              onClick={() => handleChange("toolPop", true)}
                              className={styles.actionButton}
                            >
                              <PlusBlue />
                              Add
                            </button>

                            {toolsAdded?.length > 0 && (
                              <EditGray
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsEditTools(true);
                                  handleChange("toolPop", false);
                                  setEditTool(toolsAdded);
                                }}
                              />
                            )}
                          </Row>
                        </Popover>
                      </div>

                      <div className={styles.techStack_lists}>
                        {toolsAdded?.length > 0 ? (
                          toolsAdded?.map((tool, index) => (
                            <div className={styles.techStack_list} key={index}>
                              {tool?.name}
                            </div>
                          ))
                        ) : (
                          <div className={styles.nodataAdd}>No tools added</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.form_right}>
                    <div className={styles.documents}>
                      <div className={styles.documents_heading}>
                        <h3 className={styles.heading}>Documents</h3>

                        <div className={styles.actions}>
                          <button
                            onClick={handleButtonClick}
                            className={styles.actionButton}
                            disabled={loadingUplaod}
                          >
                            <input
                              type="file"
                              ref={fileInputRef}
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              accept="image/*"
                            />
                            {loadingUplaod ? (
                              <LoadingOutlined />
                            ) : (
                              <>
                                <PlusBlue />
                              </>
                            )}
                            Add
                          </button>
                        </div>
                      </div>

                      <div className={styles.documents_lists}>
                        {files?.map((file, index) => {
                          const ext = get_url_title(file?.url);
                          return (
                            <div className={styles.documents_list} key={index}>
                              <div className={styles.documents_list_left}>
                                <div className={styles.documents_icon}>
                                  <File />
                                </div>
                                <div className={styles.documents_content}>
                                  <h3 className="text-oneline">{ext}</h3>
                                </div>
                              </div>

                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <Dropdown
                                  menu={{
                                    items: items(file),
                                  }}
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                  trigger={["click"]}
                                >
                                  <MenuDots className="cursor-pointer" />
                                </Dropdown>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.projectPlanDetails_notes}>
                <h2> Notes</h2>
                {notes?.length > 0 ? (
                  <div className={styles.notesItems}>
                    {notes?.map((note, index) => (
                      <div className={styles.notesItem} key={index}>
                        <h5>{note?.context}</h5>

                        <span>{moment(note?.updated_at).fromNow()}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={styles.noItems}>
                    <h5>No notes available</h5>
                  </div>
                )}

                <div className={styles.addNote}>
                  <input
                    placeholder="Add note"
                    onChange={(e) => {
                      let value = e.target.value;

                      if (value.startsWith(" ")) {
                        value = value.trimStart();
                      }

                      setValue(value);
                    }}
                    value={value}
                  />

                  <button onClick={handleAddNote} disabled={!value}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.projectPlanDetails_actions}>
            <button onClick={handleCanel}>Cancel</button>
            <button
              onClick={() => handleCreate(false)}
              disabled={
                isPlanGenerationLoading || !name || !start_line || !dead_line
              }
              loading={isPlanGenerationLoading}
            >
              {isPlanGenerationLoading ? <LoadingOutlined /> : "Create Plan"}
            </button>
            <button
              onClick={() => handleCreate(true)}
              disabled={
                isAIPlanGenerationLoading || !name || !start_line || !dead_line
              }
              loading={isAIPlanGenerationLoading}
            >
              {isAIPlanGenerationLoading ? (
                <LoadingOutlined />
              ) : (
                <>
                  <img src={Stars} />
                  <p>Generate Plan with AI</p>
                </>
              )}
            </button>
          </div>
        </AppLayout>
      )}

      <Modal
        footer={null}
        closable={false}
        onCancel={handleCloseEditModel}
        title={false}
        open={isVerifiedEmail}
        centered
        width={450}
        className="disablePadding"
      >
        <div className="p-24">
          <Row justify="space-between mb-4">
            <div className="font-18 text_black text_500">
              Invite collaborators to your project
            </div>
            <Cross className={"c-pointer"} onClick={handleCloseEditModel} />
          </Row>

          <h6
            style={{
              fontSize: 12,
              fontWeight: 400,
              color: "#737373",
              marginBottom: 30,
            }}
          >
            {inviteEmail}
          </h6>

          <p
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "#404040",
            }}
          >
            The added collaborator doesn’t have a Project Manager account. Email
            a registration link to create an account and get added to your
            project.
          </p>
        </div>

        <Row
          justify="end"
          style={{
            backgroundColor: "#f5f5f5",
            padding: "16px 24px",
          }}
        >
          <Button
            type="primary"
            loading={registrationLoading}
            disabled={registrationLoading}
            onClick={handleInviteUser}
            className="inviteCollaboratorBtn"
          >
            {"Send Registration link"}
          </Button>
        </Row>
      </Modal>

      <Modal
        footer={null}
        onCancel={() => handleChange("inviteProjectModal", false)}
        title={false}
        open={inviteProjectModal}
      >
        <div className="font-18 mb-1">Share this task</div>
        <div>
          Sharing <b className="underline">{name}</b> task
        </div>
        <div className="ownerModalEmail">
          <div className="ownerEmailDiv">{inviteProjectEmail}</div>
        </div>
        <Button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          icon={<MailOutlined />}
          className="inviteButton"
          type="primary"
          loading={inviteLoading}
          disabled={!inviteProjectEmail || inviteLoading}
          onClick={handleInviteProject}
        >
          Invite
        </Button>
      </Modal>

      <Modal
        footer={null}
        closable={false}
        onCancel={handleCloseEditModel}
        title={false}
        open={isEditTools}
        centered
        width={400}
        className="disablePadding"
      >
        <div className="p-24">
          <Row justify="space-between mb-4">
            <div className="font-18 text_black text_500">Edit Tool</div>
            <Cross className={"c-pointer"} onClick={handleCloseEditModel} />
          </Row>

          <div className="toolsUpdateModel">
            {editTool?.length > 0 ? (
              editTool?.map((tool, index) => (
                <div>
                  <Row
                    justify="space-between"
                    align={"middle"}
                    style={{
                      marginBottom: 8,
                    }}
                  >
                    <div className="font-12 mb-1 text_400">
                      Tool {index + 1} Name
                    </div>
                    <DeleteSmall
                      className={"c-pointer"}
                      onClick={() => handleDelete(tool)}
                    />
                  </Row>

                  <AppInput
                    value={tool?.name}
                    onChange={(name, value) => handleEdit(value, tool)}
                    placeholder="Choose a collaborator"
                    height={36}
                    borderRadius={4}
                    notPadding
                  />
                </div>
              ))
            ) : (
              <div className="empty-div">No tools added yet</div>
            )}
          </div>
        </div>

        <Divider
          className="width110"
          style={{
            margin: "10px 0px",
          }}
        />
        <Row
          justify="end"
          style={{
            padding: "0px 24px 24px",
          }}
        >
          <Button
            type="primary"
            onClick={handleUpdateTool}
            className="createPlanBtn"
          >
            {"Save"}
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default CreateProjectPlan;
