import React from "react";
import classes from "./finalizeDecision.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cc from "classnames";
// import {
//   getOption,
//   updateDecision,
// } from "../../../../modules/actions/DecisionMatrixActions";

import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as CupIcon } from "./cup.svg";
import { GetOption, SetOption } from "redux/DecisionMatrix/GetOption/action";
import { UpdateDecision } from "redux/DecisionMatrix/UpdateDecision/action";
import { Modal } from "antd";

const FinalizeDecision = ({ options, onClose, onShowWinner }) => {
  const { decisionId } = useParams();
  const dispatch = useDispatch();

  //   const { userDecisions, userOption } = useSelector((state) => state.decision);
  const { success: decision } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );
  const { success: userOption } = useSelector(
    (state) => state.getOptionReducer
  );
  const [optionsOrder, setOptionsOrder] = useState([]);

  const [sort, setSort] = useState({
    type: null,
    value: null,
    shortlist: false,
  });
  const [optionsState, setOptionsState] = useState([]);
  const [finalizeId, setFinalizeId] = useState(null);
  const [isEnable, setIsEnable] = useState(true);

  useEffect(() => {
    if (!userOption) return;
    if (
      (userOption?.length && !sort?.type && !optionsOrder?.length) ||
      userOption?.length !== optionsOrder?.length
    ) {
      setOptionsState(
        [...userOption]
          ?.sort((a, b) => a?.id - b?.id)
          ?.map((option, i) => ({
            ...option,
            order: i,
          }))
      );
      setOptionsOrder(
        [...userOption]
          ?.sort((a, b) => b?.id - a?.id)
          ?.map((option, i) => ({
            id: option?.id,
            order: i,
          }))
      );
    } else if (optionsOrder?.length) {
      setOptionsState(
        [...userOption]
          ?.map((option) => ({
            ...option,
            order: optionsOrder?.find((o) => o?.id === option?.id)?.order,
          }))
          ?.sort((a, b) => b?.order - a?.order)
      );
    }
  }, [userOption, optionsOrder]);


  useEffect(() => {
    if (options) {
      setFinalizeId(decision?.final_option?.id);
    }
  }, [options]);

  useEffect(() => {
    dispatch(GetOption(decisionId));

    return () => {
      setOptionsState([]);
      SetOption(null);
      setFinalizeId(null);
    };
  }, [decisionId]);


  const onOptionChangeHandle = (o) => () => {
    if (
      o?.name &&
      parseInt(o?.adjusted_score) > 0 &&
      parseInt(o?.weighted_score) > 0
    ) {
      setIsEnable(false);
      if (finalizeId === o?.id) {
        setFinalizeId(null);
      } else {
        setFinalizeId(o?.id);
      }
    } else {
      setIsEnable(true);
    }
  };

  return (
    <>
      {/* <div className={classes.background} /> */}
      {/* <div className={classes.modal}> */}
      <Modal
        open={true}
        footer={null}
        bodyStyle={{ padding: "0" }}
        closable={null}
        width={744}
      >
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <span>Finalize Decision</span>
            <button
              type="button"
              className={classes.close}
              onClick={onClose}
              title="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className={classes.body}>
            <div className={classes.column}>
              <div className={classes.title}>
                <p>Rank</p>
              </div>
              {optionsState?.map((o, i) => (
                <div
                  key={o?.id}
                  className={cc(classes.box, {
                    [classes.winner]: finalizeId === o?.id,
                  })}
                >
                  {i + 1}
                </div>
              ))}
            </div>
            <div className={classes.column2}>
              <div className={classes.title}>
                <p>Options</p>
              </div>
              {optionsState?.map((o) => (
                <div
                  key={o?.id}
                  className={cc(classes.box, classes.boxstyle, {
                    [classes.winner]: finalizeId === o?.id,
                  })}
                >
                  <p style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    <span>{o.name}</span>
                    <span>{finalizeId === o?.id && <CupIcon />}</span>
                  </p>
                </div>
              ))}
            </div>
            <div className={classes.column3}>
              <div className={classes.title}>
                <p>Adjusted Score %</p>
              </div>
              {optionsState?.map((o) => (
                <div
                  key={o?.id}
                  className={cc(classes.box, {
                    [classes.winner]: finalizeId === o?.id,
                  })}
                >
                  {o?.adjusted_score}%
                </div>
              ))}
            </div>
            <div className={classes.column3}>
              <div className={classes.title}>
                <p>Weighted Avg Score</p>
              </div>
              {optionsState?.map((o) => (
                <div
                  key={o?.id}
                  className={cc(classes.box, {
                    [classes.winner]: finalizeId === o?.id,
                  })}
                >
                  {o?.weighted_score}
                </div>
              ))}
            </div>
            <div className={classes.column}>
              <div className={classes.title}>
                <p>Select</p>
              </div>
              {optionsState?.map((o) => (
                <div
                  key={o?.id}
                  className={cc(classes.box, {
                    [classes.winner]: finalizeId === o?.id,
                  })}
                >
                  <input
                    style={{
                      cursor:
                        o?.name &&
                        parseInt(o?.adjusted_score) > 0 &&
                        parseInt(o?.weighted_score) > 0
                          ? "pointer"
                          : "not-allowed",
                    }}
                    type={"checkbox"}
                    checked={finalizeId === o?.id}
                    onChange={
                      o?.name &&
                      parseInt(o?.adjusted_score) > 0 &&
                      parseInt(o?.weighted_score) > 0
                        ? onOptionChangeHandle(o)
                        : null
                    }
                    id=""
                  />
                </div>
              ))}
            </div>
          </div>

          <div className={classes.footer}>
            <button
              type="button"
              disabled={!finalizeId || isEnable}
              onClick={() => {
                dispatch(
                  UpdateDecision(
                    {
                      id: decisionId,
                      body: { final_option: finalizeId },
                    },
                    onShowWinner,
                    true
                  )
                );

                if (finalizeId) onShowWinner();

                onClose();
              }}
            >
              Finalize
            </button>
          </div>
        </div>
      </Modal>
      {/* </div> */}
    </>
  );
};

export { FinalizeDecision };
