import { Button, Col, Modal, Row } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as Cross } from "assets/svg/deleteIcon.svg";
import { ReactComponent as ArrowDownGray } from "assets/svg/arrowDownGray.svg";
import { ReactComponent as DeleteBox } from "assets/svg/colorTrash.svg";
import { ReactComponent as LightStar } from "assets/svg/lightStar.svg";

import AppInput from "components/AppInput";
import { PostMetric } from '../../../modules/actions/PlanActions';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import classes from "../../PlanDetails/styles.module.scss"
export default function ManageCustomMetricsModal({
  visible,
  setModalOpen,
  goalId,
  project_plan,
  IsAddMetric,
  setIsAddMetric
}) {
  const [metrics, setMetrics] = useState([]);
  const [deletedMetrics, setDeletedMetrics] = useState([]);
  const [deletedUnits, setDeletedUnits] = useState([]);
  const [expandedMetricIndex, setExpandedMetricIndex] = useState();
  const [disableBtn, setDisableBtn] = useState(true);
  const [errors, setErrors] = useState([]);
  const [disableScroll, setDisableScroll] = useState(true);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef();
  const dispatch = useDispatch();
  const { planDetails, } = useSelector((state) => state.plan.plan);
  const rowRefs = useRef([]);
  useEffect(() => {
    if (disableScroll) {
      containerRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [visible, metrics, disableScroll]);

  useEffect(() => {
    const initialMetrics =
      planDetails?.goal.custom_labels.map(label => ({
        metric: label.value || "",
        units: label.custom_unit_options.map(option => ({ value: option.value, id: option.id })) || [""],
        metricId: label.id
      }))
    if (!IsAddMetric) {
      setMetrics([...initialMetrics, { metric: "", units: [""] }]);
    } else
      setMetrics([...initialMetrics]);
    setExpandedMetricIndex(metrics?.length - 1);
  }, [planDetails, visible, IsAddMetric]);


  const addMetric = () => {
    setDisableScroll(true)
    const updatedMetrics = [...metrics, { metric: "", units: [""] }];
    setMetrics(updatedMetrics);
    setExpandedMetricIndex(updatedMetrics?.length - 1);
    setErrors([])
    containerRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };


  const removeMetric = (index) => {
    const updatedMetrics = metrics.filter((_, i) => i !== index);
    const metricToRemove = metrics[index];

    if (metricToRemove?.metricId) {
      setDeletedMetrics([...deletedMetrics, metricToRemove?.metricId]);
    }
    setMetrics(updatedMetrics);
    if (metrics[index]?.metric?.length != 0) {
      updateDisableBtn(updatedMetrics);
    }
    setExpandedMetricIndex(null);
  };

  const handleMetricChange = (index, value) => {
    setDisableScroll(false)
    const updatedMetrics = metrics.map((metric, i) =>
      i === index ? { ...metric, metric: value } : metric
    );
    if (errors[index]) {
      const updatedErrors = [...errors];
      updatedErrors[index] = { ...updatedErrors[index], metricError: null };
      setErrors(updatedErrors);
    }
    setMetrics(updatedMetrics);
    updateDisableBtn(updatedMetrics);
  };

  const addUnit = (metricIndex) => {

    const updatedMetrics = metrics.map((metric, i) => {
      if (i === metricIndex) {
        return { ...metric, units: [...metric.units, ""] };
      }
      return metric;
    });
    setMetrics(updatedMetrics);
  };


  const handleUnitChange = (metricIndex, unitIndex, value) => {
    setDisableScroll(false)
    const updatedMetrics = metrics.map((metric, i) => {
      if (i === metricIndex) {
        const updatedUnits = metric.units.map((unit, j) =>
          j === unitIndex ? { ...unit, value } : unit
        );
        return { ...metric, units: updatedUnits };
      }
      return metric;
    });

    const updatedErrors = [...errors];
    if (updatedErrors[metricIndex] && updatedErrors[metricIndex].unitErrors) {
      updatedErrors[metricIndex].unitErrors[unitIndex] = null;
    }
    setMetrics(updatedMetrics);
    updateDisableBtn(updatedMetrics);
  };



  const removeUnit = (metricIndex, unitIndex) => {
    const updatedMetrics = metrics.map((metric, i) => {
      if (i === metricIndex) {
        const updatedUnits = metric.units.filter((_, j) => j !== unitIndex);
        const unitToRemove = metric.units[unitIndex];
        if (unitToRemove.id) {
          setDeletedUnits([...deletedUnits, unitToRemove.id]);
        }

        if (updatedUnits?.length === 0) {
          if (metric.metricId) {
            setDeletedMetrics([...deletedMetrics, metric.metricId]);
          }
          return null;
        }

        return { ...metric, units: updatedUnits };
      }
      return metric;
    }).filter(metric => metric !== null);

    setMetrics(updatedMetrics);
    if (metrics[metricIndex]?.metric?.length != 0) {
      updateDisableBtn(updatedMetrics)
    }
  };

  const handleCloseEditModel = () => {
    setModalOpen(false);
    setDisableBtn(true)
    setDeletedMetrics([])
    setDeletedUnits([])
    setErrors([])
    setDisableScroll(true)
    setIsAddMetric(false)
  };

  const validateForm = () => {
    let formIsValid = true;

    const updatedErrors = metrics.map((metric) => ({
      metricError: metric.metric.trim() === "",
      unitErrors: metric.units.map((unit) => {
        const isUnitEmpty = unit.value?.trim() === "";
        const isUnitInvalid = unit == [""];
        return isUnitEmpty || isUnitInvalid;
      }),
    }));
    setErrors(updatedErrors);
    updatedErrors.forEach((error) => {
      if (error.metricError || error.unitErrors.includes(true)) {
        formIsValid = false;
      }
    });
    return formIsValid;
  };

  const handleSubmitMetric = async () => {
    if (validateForm()) {
      setLoading(true);
      const payload = {
        metrics: metrics
          .filter(({ metric, units }) => metric && units.some(unit => unit))
          .map(({ metric, units, metricId }) => ({
            ...(metricId && { id: metricId }),
            value: metric,
            goal: goalId,
            units: units
              .filter(unit => unit && unit.value)
              .map(unit => (unit.id ? { id: unit.id, value: unit.value } : unit)),
          })),
        deleted_metrics: deletedMetrics,
        deleted_units: deletedUnits,
      };

      try {
        await dispatch(PostMetric(payload, project_plan, handleCloseEditModel));
      } catch (error) {
        console.error("Error submitting metrics:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    } else {
      console.log("Form is not valid, please fix the errors.");
      const firstErrorIndex = errors.findIndex(
        (err) => err.metricError || err.unitErrors?.some((error) => error === true)
      );

      if (firstErrorIndex) {
        setTimeout(() => {
          rowRefs?.current[firstErrorIndex]?.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 100);
      }

    }
  };
  const updateDisableBtn = (updatedMetrics) => {
    const hasDeletions = deletedUnits || deletedMetrics
    const hasActiveMetricOrUnit = updatedMetrics.some(({ metric, units }) =>
      metric.trim() !== "" || units.some(unit => unit && unit.value && unit.value.trim() !== ""));
    setDisableBtn(!(hasDeletions || hasActiveMetricOrUnit));
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !disableBtn) {
      handleSubmitMetric();
    }
  };
  return (
    <Modal
      height={400}
      closable={false}
      onCancel={handleCloseEditModel}
      title={false}
      className={classes.ManageCustomMetricsStyle}
      open={visible}
      footer={
        <Row justify="end" style={{ backgroundColor: "#F5F5F5", padding: "10px", margin: -10 }}>
          <Button
            className={classes.CancelButton}
            onClick={handleCloseEditModel}
          >
            Cancel
          </Button>
          <Button
            disabled={disableBtn || loading}
            style={{ backgroundColor: "#1271A6", color: "white", width: "88px", opacity: disableBtn ? 0.5 : 1 }}
            onClick={handleSubmitMetric}
            onKeyDown={(e) => handleKeyDown(e)}
          >
            {loading ? (
              <Spinner animation="border" size="sm" style={{ color: "white" }} />
            ) : (
              "Save"
            )}
          </Button>
        </Row>
      }
    >
      <Row justify="space-between" className="mb-2">
        <div className="font-16 text_black text_bold" style={{ fontFamily: "Poppins" }}>Manage Custom Metrics</div>
        <Cross onClick={
          handleCloseEditModel
        } className="c-pointer" />
      </Row>
      <div
        className={classes.MetricsContainer}

      >
        {metrics?.map((metric, metricIndex) => (
          <div key={metricIndex} style={{
            backgroundColor: expandedMetricIndex === metricIndex ? "#F5F5F5" : "transparent", padding: 10, marginBottom: 10,
          }
          }

          >
            <Row
              ref={(el) => (rowRefs.current[metricIndex] = el)}
              className={`mb-2 p-1 `}
              style={{
                border:
                  (errors[metricIndex]?.metricError ||
                    errors[metricIndex]?.unitErrors?.some((error) => error === true)) &&
                    expandedMetricIndex !== metricIndex
                    ? "1px solid red"
                    : "",
                borderRadius: 5
              }}

            >
              <Col span={22} className=" d-flex align-items-center gap-3 cursor-pointer" onClick={() => setExpandedMetricIndex(expandedMetricIndex === metricIndex ? null : metricIndex)}>
                <ArrowDownGray style={{ transform: expandedMetricIndex === metricIndex ? 'rotate(360deg)' : 'rotate(270deg)', transition: 'transform 0.3s' }} />
                <h5 style={{ color: expandedMetricIndex === metricIndex ? "#1271A6" : "#1B2A3D", fontSize: 14, margin: 0, }}>{metric.metric || "Metric"}</h5>
              </Col>
              <Col span={2} className="d-flex justify-content-end align-items-center  gap-10">
                <DeleteBox onClick={() =>

                  removeMetric(metricIndex)} className="c-pointer " />
              </Col>
            </Row>
            {expandedMetricIndex === metricIndex && (
              <>
                <Col span={22}>
                  <label className={classes.label}>Metric</label>
                  <AppInput
                    maxLength="12"
                    // label="Metric"
                    placeholder="Enter Metric Label"
                    value={metric.metric}
                    name="metric"
                    onChange={(name, value) => handleMetricChange(metricIndex, value)}
                    marginBottom={10}

                    onKeyDown={(e) => handleKeyDown(e)}

                  />
                </Col>
                {errors[metricIndex]?.metricError && <div className={classes.ErrorText}>This field cannot be blank</div>}
              </>

            )}
            {expandedMetricIndex === metricIndex && metric.units.map((unit, unitIndex) => (
              <Row key={unitIndex}>
                <Col span={22}>
                  <label className={classes.label}>Unit</label>
                  <AppInput
                    maxLength="6"

                    placeholder="Unit Name"
                    value={unit.value || ""}
                    name="unit"
                    onChange={(name, value) => handleUnitChange(metricIndex, unitIndex, value)}
                    marginBottom={10}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                  {errors[metricIndex]?.unitErrors[unitIndex] && <div className={classes.ErrorText}>This field cannot be blank</div>}
                </Col>
                <Col span={2} className="d-flex justify-content-end align-items-center mt-3">
                  <DeleteBox
                    onClick={() => {
                      if (metrics[metricIndex].units?.length > 1) {
                        removeUnit(metricIndex, unitIndex);
                      }
                    }}
                    className={`c-pointer ${metrics[metricIndex].units?.length === 1 ? 'disabled' : ''}`}
                    style={{
                      opacity: metrics[metricIndex].units?.length === 1 ? 0.5 : 1,

                    }}
                  />
                </Col>
              </Row>
            ))}
            {expandedMetricIndex === metricIndex && (
              <Col span={24} className="d-flex justify-content-end">
                <h5
                  onClick={() => {
                    const lastUnit = metrics[metricIndex]?.units.at(-1);
                    if (lastUnit?.value?.trim()) {
                      addUnit(metricIndex);
                    }
                  }}
                  style={{
                    opacity: metrics[metricIndex]?.units.at(-1)?.value?.trim() ? 1 : 0.5,
                    color:

                      "#1271A6",

                    fontSize: 14,
                    cursor:
                      metrics[metricIndex]?.units.at(-1)?.value?.trim()
                        ? "pointer"
                        : "not-allowed",
                  }}
                >
                  <span style={{ fontSize: 18 }}>+ </span> Add Unit
                </h5>
              </Col>
            )}
          </div>
        ))}
        <div ref={containerRef} style={{ height: 1 }} />
      </div>
      <div className="d-flex justify-content-end">
        <h5 onClick={addMetric} style={{ color: "#1271A6", fontSize: 14, cursor: "pointer" }}>
          <span style={{ fontSize: 18 }}>+ </span> Add Metric
        </h5>
      </div>
    </Modal>
  );
}
