import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TemplatePreview } from "../TemplatePreview/TemplatePreview";
import styles from "./decisionTemplateCard.module.scss";
import { CreateDecisionFromTemplate } from "redux/DecisionMatrix/CreateDecisionFromTemplate/action";
import { useNavigate } from "react-router-dom";
import { ALLOCATOR_TOOL, DECISION_COACH } from "../../../../utils/routes";

const DecisionTemplate = ({ template, forAllocator }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPreview, setIsPreview] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const hanldeClickAll = () => {
    let templateIdArr = [];
    templateIdArr = template?.criteria?.map((item) => item?.id);
    let criteria_list = templateIdArr.join(",");

    dispatch(
      CreateDecisionFromTemplate(
        {
          template: template?.id,
          criteria_list,
        },
        handleCallback
      )
    );
  };

  const handleCallback = decision => {
    navigate(`/${forAllocator ? ALLOCATOR_TOOL : DECISION_COACH}/${decision?.id}`);
  }

  return (
    <>
      {isPreview && (
        <TemplatePreview
          setSelectedCheckboxes={setSelectedCheckboxes}
          selectedCheckboxes={selectedCheckboxes}
          template={template}
          forAllocator={forAllocator}
          onClose={() => {setIsPreview(false) ;setSelectedCheckboxes([])}}
        />
      )}
      <div className={styles.decision_templates}>
        <div className={styles.decision_gradient}></div>
        <div className={styles.decision_header}>
          <div className={styles.title}>
            <h5>{template?.name}</h5>
            {/* <a href="#">Domain/Area/Category</a> */}
          </div>
          <div className={styles.preview}>
            <a href="#" onClick={() => setIsPreview(true)}>
              PREVIEW
            </a>
          </div>
        </div>
        <div className={styles.decision_addition}>
          <button onClick={hanldeClickAll}>
            <span>+</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default DecisionTemplate;
