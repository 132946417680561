import styled from "styled-components";

export const NoteContent = styled.main`
  padding-left: 38px;
`;

export const NoteHeading = styled.h6`
  color: var(--neutral-900, var(--Color-2, #171717));
  font-weight: 400;
  font-size: 10px;
  font-family: Inter;
  margin-top: 9px;
`;

export const NoteContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  opacity: 0;
  visibility: hidden;
`;

export const NoteText = styled.span<{ isNoteButton?: boolean }>`
  color: var(--Neutral-500, #a3a3a3);
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  word-break: break-word;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  -webkit-line-clamp: ${({ isNoteButton }) => (isNoteButton ? "inherit" : "2")};
  max-height: ${({ isNoteButton }) => (isNoteButton ? "max-content" : "40px")};
`;

export const ToggleButton = styled.button`
  margin: 0;
  padding: 0;
  margin-top: 15px;
  background: none;
  border: none;

  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #1271a6;
`;

export const AddNote = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: none;
  border: none;
  color: #ff9900;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  padding: 0;
`;
